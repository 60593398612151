import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import { API_ENDPOINT } from '../consts/globals';
import { CitiesContext } from './CitiesContext';
import {
  generateFiltersQueryString,
  generateSortersQueryString
} from 'components/helpers/tableOrderAndSortHelpers';
import { CountriesContext } from './CountriesContext';

export const CompaniesContext = createContext();

const CompaniesContextProvider = (props) => {
  const { countries } = useContext(CountriesContext);
  const { cities, getCities } = useContext(CitiesContext);
  const { authFetch } = useContext(AuthContext);
  const [companies, setCompanies] = useState([]);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const [totalCompanies, setTotalCompanies] = useState(null);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const getCompanies = async (
    pageSize = 10,
    pageNumber = 1,
    filters,
    sorter
  ) => {
    setLoading(true);

    const includes = 'city,country,certificates,medias';

    try {
      const appendFilters = generateFiltersQueryString(filters);
      const appendSorter = generateSortersQueryString(sorter);
      const response = await authFetch(
        `${API_ENDPOINT}/system/companies?include=${includes}&page-size=${pageSize}&page-number=${pageNumber}${appendFilters}${appendSorter}`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setCompanies(responseJson.data);
      setTotalCompanies(responseJson.meta?.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getLeanAnswers = async (companyId) => {
    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/company-questions/${companyId}`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      return responseJson;
    } catch (error) {
      console.log(error);
    }
  };

  const getQuickScanAnswers = async (companyId) => {
    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/quick-scan/${companyId}`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      return responseJson;
    } catch (error) {
      console.log(error);
    }
  };

  const createCompany = async (company, shouldGetCompanies = true) => {
    setResponse({});

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/stepone`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(company)
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      if (shouldGetCompanies) {
        getCompanies();
      }
      console.log('response', responseJson);

      return responseJson;
    } catch (error) {
      console.log(error);
    }
  };

  const createJsonCompany = async (json, shouldGetCompanies = true) => {
    setResponse({});

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/import/company`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: json
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      if (shouldGetCompanies) {
        getCompanies();
      }
      console.log('response', responseJson);

      return responseJson;
    } catch (error) {
      console.log(error);
    }
  };

  const createCompanyLean = async (companyLean) => {
    setResponse({});

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/company-questions`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(companyLean)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createCompanyQuickScan = async (companyQuickScan) => {
    setResponse({});

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/quick-scan`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(companyQuickScan)
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editCompany = async (company, id) => {
    setResponse({});

    const data = new FormData();
    data.append('data', JSON.stringify(company));

    company.medias?.fileList?.forEach((file) => {
      data.append('files[]', file);
      data.append('file_types[]', 'image');
    });

    if (company.reference_list) {
      data.append('files[]', company.reference_list);
      data.append('file_types[]', 'reference-list');
    }

    if (company.logo) {
      data.append('files[]', company.logo);
      data.append('file_types[]', 'logo');
    }

    company.company_presentation?.fileList?.forEach((file) => {
      data.append('files[]', file);
      data.append('file_types[]', 'company-presentation');
    });

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/stepone/${id}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json'
        },
        body: data
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCompanies();
    } catch (error) {
      console.log(error);
    }
  };

  const editCompanyLean = async (companyLean, companyId) => {
    setResponse({});

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/company-questions/${companyId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(companyLean)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editCompanyQuickScan = async (companyQuickScan, companyId) => {
    setResponse({});

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/quick-scan/${companyId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(companyQuickScan)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editCompanySupplierProducts = async (companySupplierProducts) => {
    setResponse({});

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/company-categories`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(companySupplierProducts)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editCompanyOwnership = async (company, id) => {
    setResponse({});

    const params = {
      company_id: id,
      new_owner: company.owner_id
    };

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/transfer-ownership`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(params)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCompanies();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCompany = async (id) => {
    setResponse({});
    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/companies/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCompanies();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCities();
    getCompanies();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CompaniesContext.Provider
      value={{
        companies,
        cities,
        countries,
        getCompanies,
        getLeanAnswers,
        getQuickScanAnswers,
        totalCompanies,
        createCompany,
        createJsonCompany,
        createCompanyLean,
        createCompanyQuickScan,
        editCompany,
        editCompanyLean,
        editCompanyQuickScan,
        editCompanySupplierProducts,
        editCompanyOwnership,
        deleteCompany,
        response,
        setResponse,
        loading
      }}
    >
      {props.children}
    </CompaniesContext.Provider>
  );
};

export default CompaniesContextProvider;
