import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input } from 'antd';

import { CompaniesContext } from '../../contexts/CompaniesContext';
import { defaultFormLayout } from '../../consts/formStyles';
import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';

const { TextArea } = Input;

const CreateJsonCompany = (props) => {
  const { createJsonCompany, response, setResponse } =
    useContext(CompaniesContext);
  const { modalVisible, setModalVisible, modal } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        createJsonCompany(values.json, props.shouldGetCompanies).then(
          (data) => {
            if (data && props.onFinishCreate) {
              props.onFinishCreate(data);
              console.log('successfully added json', data);
            }
          }
        );
      })
      .catch((info) => {
        console.log('error happened', info);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'json') {
    return (
      <Modal
        title={`Add new company`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Json object"
            name={['json']}
            {...ajaxErrorFieldValidationProperties(response, 'json')}
            rules={[{ required: true, message: 'Please input a json object!' }]}
          >
            <TextArea placeholder="json" />
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default CreateJsonCompany;
