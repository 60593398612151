import React, { useState, useEffect, createContext, useContext } from 'react';
import { AuthContext } from './AuthContext';
import { REACT_APP_MSG_ENDPOINT } from '../consts/globals';

export const MessagesContext = createContext();

const MessagesContextProvider = (props) => {
  const [messages, setMessages] = useState([]);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const { authFetch, cognitoUser } = useContext(AuthContext);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const getMessages = async () => {
    setLoading(true);
    try {
      const response = await authFetch(
        `${REACT_APP_MSG_ENDPOINT}/api/messages`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            Authorization:
              'Bearer ' + cognitoUser.signInUserSession.idToken.jwtToken
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();
      console.log(responseJson);
      setMessages(responseJson.Items);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Error:', error);
    }
  };

  const createMessage = async (message) => {
    setResponse({});

    try {
      const response = await authFetch(
        `https://dev.marrowlabs.com/yvoomsgservice/api/messages`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            Authorization:
              'Bearer ' + cognitoUser.signInUserSession.idToken.jwtToken
          },
          body: JSON.stringify(message)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getMessages();
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const editMessage = async (selectedMessage, message) => {
    setResponse({});

    const modifiedMessage = {
      product: message.product,
      productLocation: message.productLocation,
      fullResponse: {
        question: selectedMessage.fullResponse.question,
        response: message.fullResponse
      }
    };
    const object = {
      ...modifiedMessage,
      dateCreated: selectedMessage.dateCreated,
      userEmail: selectedMessage.userEmail,
      selfAssesment: false,
      quickscan: false,
      audit: false,
      material: ''
    };

    try {
      const response = await authFetch(
        `https://dev.marrowlabs.com/yvoomsgservice/api/messages/${selectedMessage.id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            Authorization:
              'Bearer ' + cognitoUser.signInUserSession.idToken.jwtToken
          },
          body: JSON.stringify(object)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getMessages();
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const deleteMessage = async (id) => {
    setResponse({});

    try {
      const response = await authFetch(
        `https://dev.marrowlabs.com/yvoomsgservice/api/messages/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getMessages();
    } catch (error) {
      console.log('Error:', error);
    }
  };

  useEffect(() => {
    getMessages();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MessagesContext.Provider
      value={{
        messages,
        getMessages,
        createMessage,
        editMessage,
        deleteMessage,
        response,
        setResponse,
        loading
      }}
    >
      {props.children}
    </MessagesContext.Provider>
  );
};

export default MessagesContextProvider;
