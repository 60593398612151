import React, { useContext, useState, Fragment } from 'react';
import {
  Col,
  Drawer,
  Row,
  Modal,
  Input,
  Table,
  Button,
  Divider,
  Form
} from 'antd';
import styled from 'styled-components';

import {
  ArrowUpOutlined,
  DeleteOutlined,
  ArrowDownOutlined,
  EditOutlined
} from '@ant-design/icons';
import { CustomSearchContext } from '../../contexts/CustomSearchContext';
import CreateCompany from '../companies/CreateCompany';
import DeleteSearch from './DeleteSearch';
import columnSearch from '../column-search/CustomColumnSearch';

const StyledButtonWrapper = styled.div`
  display: flex;
`;

const StyledForm = styled.div`
  display: flex;

  button {
    height: 40px;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const StyledRow = styled(Row)`
  h4 {
    font-weight: bold;
  }

  ul {
    padding-left: 0;
  }
`;

const CustomSearchList = () => {
  const {
    totalSearches,
    getCustomSearches,
    customSearches,
    getCustomSearch,
    getSuppliers,
    postLongList,
    loading
  } = useContext(CustomSearchContext);
  const [isCreateCompanyModalVisible, setIsCreateCompanyModalVisible] =
    useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedCustomSearch, setSelectedCustomSearch] = useState({});
  const [isVisibleAddSupplierModal, setIsVisibleAddSupplierModal] =
    useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliersList, setSelectedSupplierList] = useState([]);
  const [addedSuppliersMap] = useState(new Map());
  const [form] = Form.useForm();
  const [modal, setModal] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const openDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const getSelectedCustomSearch = (id) => {
    getCustomSearch(id).then((data) => {
      setSelectedCustomSearch(data);
    });
  };

  const NoDataMessage = () => <i>No data available</i>;

  const columns = [
    {
      title: 'User ID',
      dataIndex: 'user_uuid',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('user ID')
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('industry')
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('ID')
    },
    {
      title: 'Synonyms',
      dataIndex: 'synonims',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('synonyms')
    },
    {
      title: 'Action',
      render: (customSearch) => (
        <span>
          <StyledIcon
            onClick={() => {
              setSelectedCustomSearch(customSearch);
              openDrawer();
              getSelectedCustomSearch(customSearch.id);
            }}
          >
            <EditOutlined />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('delete');
              setSelectedCustomSearch(customSearch);
            }}
          >
            <DeleteOutlined />
          </StyledIcon>
        </span>
      )
    }
  ];

  const supplierColumns = [
    {
      title: 'Industry',
      dataIndex: 'industry'
    },
    // {
    //   title: 'DUNS number',
    //   dataIndex: 'duns_number'
    // },
    // {
    //   title: 'Tax jurisdiction code',
    //   dataIndex: 'tax_jurisdiction_code'
    // },
    // {
    //   title: 'VAT id',
    //   dataIndex: 'vat_id'
    // },
    // {
    //   title: 'EBIT',
    //   dataIndex: 'ebit'
    // },
    // {
    //   title: 'Website',
    //   dataIndex: 'website'
    // },
    // {
    //   title: 'Short description',
    //   dataIndex: 'short_description'
    // },
    {
      title: 'Address',
      dataIndex: 'address'
    },
    {
      title: 'Turnover',
      dataIndex: 'turnover'
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },
    // {
    //   title: 'Owner',
    //   dataIndex: 'owner_id'
    // },
    // {
    //   title: 'Company representative',
    //   dataIndex: 'company_representative'
    // },
    // {
    //   title: 'Reference list',
    //   dataIndex: 'reference_list'
    // },
    // {
    //   title: 'Communication Language',
    //   dataIndex: 'communication_language'
    // },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Number of employees',
      dataIndex: 'number_of_employees'
    },
    {
      title: 'Supplier Type',
      dataIndex: 'supplier_type'
    }
  ];

  const selectedSuppliersColumns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'ID',
      dataIndex: 'id'
    },
    ...supplierColumns,
    {
      title: 'Action',
      render: (text, supplier, index) => {
        return (
          <div>
            <Button
              type="secondary"
              onClick={() => {
                if (index) {
                  setSelectedSupplierList((selectedSuppliers) => {
                    let data = [...selectedSuppliers];
                    let temp = data[index - 1];
                    data[index - 1] = data[index];
                    data[index] = temp;
                    return data;
                  });
                }
              }}
            >
              <ArrowUpOutlined />
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                if (index + 1 < selectedSuppliersList.length) {
                  setSelectedSupplierList((selectedSuppliers) => {
                    let data = [...selectedSuppliers];
                    let temp = data[index + 1];
                    data[index + 1] = data[index];
                    data[index] = temp;
                    return data;
                  });
                }
              }}
            >
              <ArrowDownOutlined />
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                setSelectedSupplierList((selectedSuppliers) => [
                  ...selectedSuppliers.filter(
                    (_supplier) => _supplier.id !== supplier.id
                  )
                ]);
                addedSuppliersMap.delete(supplier.id);
              }}
            >
              Remove supplier
            </Button>
          </div>
        );
      }
    }
  ];

  const searchedSuppliersColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    ...supplierColumns,
    {
      title: 'Action',
      render: (supplier) => {
        return !addedSuppliersMap.has(supplier.id) ? (
          <Button
            type="primary"
            onClick={() => {
              setSelectedSupplierList((selectedSuppliers) => [
                ...selectedSuppliers,
                supplier
              ]);
              addedSuppliersMap.set(supplier.id, true);
            }}
          >
            Add supplier
          </Button>
        ) : (
          <Button
            type="secondary"
            onClick={() => {
              setSelectedSupplierList((selectedSuppliers) => [
                selectedSuppliers.filter(
                  (_supplier) => _supplier.id !== supplier.id
                )
              ]);
              addedSuppliersMap.delete(supplier.id);
            }}
          >
            Remove supplier
          </Button>
        );
      }
    }
  ];

  const searchForSuppliers = () => {
    form.validateFields().then((values) => {
      getSuppliers(values.term).then((data) => {
        setSuppliers(data.data);
      });
    });
  };

  const submitLongList = () => {
    postLongList(
      {
        name: selectedCustomSearch.product_name,
        custom_search_id: selectedCustomSearch.id,
        companies: selectedSuppliersList.map((supplier) => supplier.id)
      },
      selectedCustomSearch.user_uuid
    ).then(() => {
      setIsDrawerVisible(false);
    });
  };
  return (
    <Fragment>
      <Table
        columns={columns}
        rowKey={(customSearch) => customSearch.id}
        dataSource={customSearches}
        pagination={{ total: totalSearches }}
        scroll={{ x: 'max-content' }}
        loading={loading}
        onChange={(pagination, filters, sorter) => {
          getCustomSearches(
            pagination.pageSize,
            pagination.current,
            filters,
            sorter
          );
        }}
      />
      <Drawer
        title={
          <div>
            {selectedCustomSearch.product_name + ''}
            <Button
              type="primary"
              style={{ marginLeft: '0.5rem' }}
              onClick={submitLongList}
            >
              Submit longlist
            </Button>
          </div>
        }
        placement="right"
        closable={true}
        onClose={closeDrawer}
        visible={isDrawerVisible}
        width="calc(100vw - 256px)"
      >
        <StyledRow>
          <Col xs={24} lg={12}>
            <h4>Description</h4>
            <p>{selectedCustomSearch.description || <NoDataMessage />}</p>
          </Col>
          <Col xs={24} lg={4}>
            <h4>Industry</h4>
            <p>{selectedCustomSearch.industry || <NoDataMessage />}</p>
          </Col>
          <Col xs={24} lg={4}>
            <h4>Product synonyms</h4>
            <p>{selectedCustomSearch.synonims || <NoDataMessage />}</p>
            <h4>Tech parameters</h4>
            <ul>
              {selectedCustomSearch['Tech parameters'] &&
              selectedCustomSearch['Tech parameters'].length > 0 ? (
                selectedCustomSearch['Tech parameters'].map(
                  (tech_parameter, index) => (
                    <li key={index}>{tech_parameter.parameter}</li>
                  )
                )
              ) : (
                <NoDataMessage />
              )}
            </ul>
          </Col>
          <Col xs={24} lg={4}>
            <h4>Certificates</h4>
            <ul>
              {selectedCustomSearch.Certificates &&
              selectedCustomSearch.Certificates.length > 0 ? (
                selectedCustomSearch.Certificates.map((certificate, index) => (
                  <li key={index}>{certificate.name}</li>
                ))
              ) : (
                <NoDataMessage />
              )}
            </ul>
            <h4>Known suppliers</h4>
            <ul>
              {selectedCustomSearch['Known Suppliers list'] &&
              selectedCustomSearch['Known Suppliers list'].length > 0 ? (
                selectedCustomSearch['Known Suppliers list'].map(
                  (known_supplier, index) => (
                    <li key={index}>{known_supplier.name}</li>
                  )
                )
              ) : (
                <NoDataMessage />
              )}
            </ul>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col xs={24} md={4}>
            <StyledButtonWrapper>
              <StyledButton
                type="primary"
                onClick={() => {
                  setIsVisibleAddSupplierModal(true);
                }}
              >
                Add new supplier
              </StyledButton>
              <StyledButton
                type="secondary"
                onClick={() => {
                  setIsCreateCompanyModalVisible(true);
                }}
              >
                Create new supplier
              </StyledButton>
            </StyledButtonWrapper>
          </Col>
        </StyledRow>
        <hr />
        <Table
          columns={selectedSuppliersColumns}
          rowKey={(supplier) => supplier.id}
          dataSource={selectedSuppliersList}
          pagination={{ pageSize: 10 }}
          scroll={{ x: 'max-content' }}
        />
        {/* MODALS */}
        <CreateCompany
          modalVisible={isCreateCompanyModalVisible}
          setModalVisible={setIsCreateCompanyModalVisible}
          modal={'create'}
          onFinishCreate={(createdCompany) => {
            setSelectedSupplierList((selectedSuppliers) => [
              ...selectedSuppliers,
              createdCompany
            ]);
            addedSuppliersMap.set(createdCompany.id, true);
          }}
          shouldGetCompanies={false}
        />
        <Modal
          title="Add new supplier"
          visible={isVisibleAddSupplierModal}
          onCancel={() => {
            setIsVisibleAddSupplierModal(false);
          }}
          width={1024}
          footer={
            <Button
              type="secondary"
              onClick={() => {
                setIsVisibleAddSupplierModal(false);
              }}
            >
              Close
            </Button>
          }
        >
          <Form form={form}>
            <StyledForm>
              <Form.Item
                name="term"
                rules={[{ required: true, message: 'Please input a term!' }]}
              >
                <Input
                  name="term"
                  placeholder="Search term for supplier"
                  size="large"
                />
              </Form.Item>
              <Button type="primary" onClick={searchForSuppliers}>
                Search
              </Button>
            </StyledForm>
          </Form>
          <Table
            columns={searchedSuppliersColumns}
            rowKey={(supplier) => supplier.id}
            dataSource={suppliers}
            pagination={{ pageSize: 10 }}
            scroll={{ x: 'max-content' }}
          />
        </Modal>
      </Drawer>
      <DeleteSearch
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCustomSearch={selectedCustomSearch}
      />
    </Fragment>
  );
};

export default CustomSearchList;
