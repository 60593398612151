import React, { useState, createContext, useContext } from 'react';

import { AuthContext } from './AuthContext';
import { API_ENDPOINT } from '../consts/globals';

export const CategoryFamiliesContext = createContext();

const CategoryFamiliesContextProvider = (props) => {
  const { authFetch } = useContext(AuthContext);
  const [response, setResponse] = useState({});



  const createCategoryFamily = async (family) => {
    setResponse({});

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/categories-family`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(family)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CategoryFamiliesContext.Provider
      value={{
        response,
        setResponse,
        createCategoryFamily
      }}
    >
      {props.children}
    </CategoryFamiliesContext.Provider>
  );
};

export default CategoryFamiliesContextProvider;
