import React, { useContext, useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import moment from 'moment';

import CreateQuickscan from './CreateQuickscan';
import { AdminQuickscansContext } from '../../contexts/AdminQuickscansContext';
import { EditOutlined } from '@ant-design/icons';
import EditQuickscan from './EditQuickscan';
import EditQuickscanStatus from './EditQuickscanStatus';
import EditQuickscanPaid from './EditQuickscanPaid';
import columnSearch from '../column-search/CustomColumnSearch';

const StyledIcon = styled.span`
  cursor: pointer;
`;

const QuickscanList = () => {
  const { quickscans, pages, totalQuickscans, loading, getQuickscans } =
    useContext(AdminQuickscansContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [selectedQuickscan, setSelectedQuickscan] = useState({});
  const [selectedQuickscanOrder, setSelectedQuickscanOrder] = useState({});
  const [transformedQuickscans, setTransformedQuickscans] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (quickscans && quickscans.length > 0) {
      setTransformedQuickscans(quickscans);
    }
  }, [quickscans]);

  const handlePage = (e) => {
    setPage(e);
    getQuickscans(e);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('id')
    },
    {
      title: 'Supplier',
      dataIndex: ['company', 'name'],
      ...columnSearch('supplier')
    },
    {
      title: 'Client',
      dataIndex: ['client', 'email'],
      ...columnSearch('client')
    },
    {
      title: 'Status',
      sorter: 'status',
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('status'),
      render: (quickscan) => {
        return (
          <span
            onClick={() => {
              setModalVisible(true);
              setModal('quick-scan-status');
              setSelectedQuickscanOrder(quickscan);
            }}
            style={{ cursor: 'pointer' }}
          >
            {quickscan.status === 'open' ? 'open' : 'complete'}
          </span>
        );
      }
    },
    {
      title: 'Paid',
      sorter: true,
      dataIndex: 'paid',
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('paid'),
      render: (paid, quickscan) => {
        return (
          <span
            onClick={() => {
              setModalVisible(true);
              setModal('quick-scan-paid');
              setSelectedQuickscanOrder(quickscan);
            }}
            style={{ cursor: 'pointer' }}
          >
            {paid ? 'yes' : 'no'}
          </span>
        );
      }
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('created_at'),
      render: (created_at) => {
        return <span>{moment(created_at).format('YYYY-MM-DD HH:mm:ss')}</span>;
      }
    },
    {
      title: 'Action',
      render: (quickscan) => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('admin-quick-scan');
              setSelectedQuickscan(quickscan);
            }}
          >
            <EditOutlined />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <Table
        columns={columns}
        rowKey={(quickscan) => quickscan.id}
        dataSource={transformedQuickscans}
        pagination={{
          total: totalQuickscans
        }}
        onChange={(pagination, filters, sorter) => {
          getQuickscans(
            pagination.pageSize,
            pagination.current,
            filters,
            sorter
          );
        }}
        scroll={{ x: 'max-content' }}
        loading={loading}
      />
      <CreateQuickscan
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <EditQuickscan
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedQuickscan={selectedQuickscan}
        setSelectedQuickscan={setSelectedQuickscan}
      />
      <EditQuickscanStatus
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedQuickscanOrder={selectedQuickscanOrder}
        setSelectedQuickscanOrder={setSelectedQuickscanOrder}
      />
      <EditQuickscanPaid
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedQuickscanOrder={selectedQuickscanOrder}
        setSelectedQuickscanOrder={setSelectedQuickscanOrder}
      />
    </Fragment>
  );
};

export default QuickscanList;
