import React, { useState, useEffect, createContext, useContext } from 'react';

import { AuthContext } from './AuthContext';
import { API_ENDPOINT } from '../consts/globals';
import {
  generateFiltersQueryString,
  generateSortersQueryString
} from 'components/helpers/tableOrderAndSortHelpers';

export const CitiesContext = createContext();

const CitiesContextProvider = (props) => {
  const { authFetch } = useContext(AuthContext);
  const [cities, setCities] = useState([]);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const [totalCities, setTotalCities] = useState(null);

  const abortController = new AbortController();
  const signal = abortController.signal;

  /**
   *
   * @param {number} pageSize
   * @param {number} pageNumber
   */
  const getCities = async (pageSize = 10, pageNumber = 1, filters, sorter) => {
    setLoading(true);

    const includes = 'country';
    try {
      const appendFilters = generateFiltersQueryString(filters);
      const appendSorter = generateSortersQueryString(sorter);
      const response = await authFetch(
        `${API_ENDPOINT}/system/cities?include=${includes}&page-size=${pageSize}&page-number=${pageNumber}${appendFilters}${appendSorter}`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setCities(responseJson.data);
      setTotalCities(responseJson.meta?.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const createCity = async (city) => {
    setResponse({});

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/cities`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(city)
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCities();
    } catch (error) {
      console.log(error);
    }
  };

  const editCity = async (city, id) => {
    setResponse({});

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/cities/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(city)
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCities();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCity = async (id) => {
    setResponse({});

    try {
      const response = await authFetch(`${API_ENDPOINT}/system/cities/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCities();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCities();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CitiesContext.Provider
      value={{
        cities,
        getCities,
        totalCities,
        createCity,
        editCity,
        deleteCity,
        response,
        setResponse,
        loading
      }}
    >
      {props.children}
    </CitiesContext.Provider>
  );
};

export default CitiesContextProvider;
