import React, { useState } from 'react';
import { Button, Row, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CreateCategorySegment from './CreateCategorySegment';
import styled from 'styled-components';

const { Option } = Select;

const StyledButton = styled(Button)``;

const CategorySegmentsList = (props) => {
  const {
    justifyContent,
    segments,
    segmentSelected,
    getSegments,
    enableCreate,
    width
  } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');

  return (
    <Row justify={justifyContent} style={{ gap: '8px' }}>
      <Select
        showSearch
        style={{ width: width ?? '200px' }}
        placeholder="Select a segment"
        optionFilterProp="children"
        onChange={segmentSelected}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {segments.map((segment) => {
          return (
            <Option value={segment.segment} key={segment.segment}>
              {segment.segment_name}
            </Option>
          );
        })}
      </Select>
      {enableCreate ? (
        <StyledButton
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create-segment');
          }}
        />
      ) : null}

      {segments.length && enableCreate ? (
        <CreateCategorySegment
          modalVisible={modalVisible}
          modal={modal}
          category={segments[0].category_id}
          getSegments={getSegments}
          setModalVisible={setModalVisible}
        />
      ) : null}
    </Row>
  );
};

export default CategorySegmentsList;
