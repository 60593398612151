import React, { useState, createContext, useContext } from 'react';

import { AuthContext } from './AuthContext';
import { API_ENDPOINT } from '../consts/globals';

export const CategoryCommoditiesContext = createContext();

const CategoryCommoditiesContextProvider = (props) => {
  const { authFetch } = useContext(AuthContext);
  const [response, setResponse] = useState({});


  const createCategoryCommodity = async (commodity) => {
    setResponse({});

    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/categories-commodity`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(commodity)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CategoryCommoditiesContext.Provider
      value={{
        response,
        setResponse,
        createCategoryCommodity
      }}
    >
      {props.children}
    </CategoryCommoditiesContext.Provider>
  );
};

export default CategoryCommoditiesContextProvider;
