import React, { useContext, useEffect, useState } from 'react';
import { Modal, Input, Upload, Button, Checkbox, Form } from 'antd';

import { CountriesContext } from '../../contexts/CountriesContext';
import { UploadOutlined } from '@ant-design/icons';
import { defaultFormLayout } from '../../consts/formStyles';
import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';

// function hasErrors(fieldsError) {
//   return Object.keys(fieldsError).some(field => fieldsError[field]);
// }

const EditCountry = (props) => {
  const { editCountry, response, setResponse } = useContext(CountriesContext);
  const { modalVisible, setModalVisible, modal, selectedCountry } = props;
  const [visible, setVisible] = useState(selectedCountry.visible);
  const [bestCost, setBestCost] = useState(selectedCountry.best_buy);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setVisible(selectedCountry.visible);
  }, [selectedCountry]);

  useEffect(() => {
    setBestCost(selectedCountry.best_buy);
  }, [selectedCountry]);

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        values.country.visible = visible;
        values.country.best_buy = bestCost;
        editCountry(values.country, selectedCountry.id);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    //There is a some sort of race condition problem going on with initialValues and resetFields() in antd4
    //I have to use this use effect so I can reset form values to new values when form loads. Otherwise form will retain old data.
    if (modalVisible) {
      form.resetFields();
      form.setFieldsValue({
        country: {
          name: selectedCountry.name,
          iso: selectedCountry.iso
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  useEffect(() => {
    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'edit') {
    return (
      <Modal
        title={`Edit ${selectedCountry.name}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Name"
            name={['country', 'name']}
            {...ajaxErrorFieldValidationProperties(response, 'name')}
            rules={[
              { required: true, message: 'Please input a country name!' }
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="ISO"
            name={['country', 'iso']}
            {...ajaxErrorFieldValidationProperties(response, 'iso')}
            rules={[
              { required: true, message: 'Please input an ISO code!' },
              { min: 2, message: 'ISO must be exactly 2 characters.' },
              { max: 2, message: 'ISO must be exactly 2 characters.' }
            ]}
          >
            <Input name="iso" placeholder="ISO" maxLength={2} />
          </Form.Item>
          <Form.Item
            label="Visible"
            name={['country', 'visible']}
            {...ajaxErrorFieldValidationProperties(response, 'visible')}
          >
            <Checkbox
              onChange={(e) => setVisible(e.target.checked)}
              checked={visible}
            />
          </Form.Item>
          <Form.Item
            label="Best cost"
            name={['country', 'best_buy']}
            {...ajaxErrorFieldValidationProperties(response, 'best_buy')}
          >
            <Checkbox
              onChange={(e) => setBestCost(e.target.checked)}
              checked={bestCost}
            />
          </Form.Item>
          <Form.Item
            label="Flag image"
            name={['country', 'flag']}
            getValueFromEvent={normFile}
            valuePropName="fileList"
            {...ajaxErrorFieldValidationProperties(response, 'flag')}
          >
            <Upload beforeUpload={() => false}>
              <Button>
                <UploadOutlined /> Click to upload flag
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default EditCountry;
