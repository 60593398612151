import React, { useState, useEffect, createContext, useContext } from 'react';

import { AuthContext } from './AuthContext';
import { API_ENDPOINT } from '../consts/globals';
import {
  generateFiltersQueryString,
  generateSortersQueryString
} from 'components/helpers/tableOrderAndSortHelpers';

export const CustomSearchContext = createContext();

const CustomSearchContextProvider = (props) => {
  const { authFetch } = useContext(AuthContext);
  const [customSearches, setCustomSearches] = useState([]);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  const [totalSearches, setTotalSearches] = useState(null);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const getCustomSearches = async (
    pageSize = 10,
    pageNumber = 1,
    filters,
    sorter
  ) => {
    setLoading(true);
    const appendFilters = generateFiltersQueryString(filters);
    const appendSorter = generateSortersQueryString(sorter);
    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/custom-search?page-size=${pageSize}&page-number=${pageNumber}${appendFilters}${appendSorter}`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setCustomSearches(responseJson.data);
      setTotalSearches(responseJson.meta?.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getCustomSearch = async (id) => {
    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/custom-search/${id}?include=certificates, knownSuppliers, techParameters`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      return responseJson;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCustomSearch = async (id) => {
    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/custom-search/${id}`,
        {
          signal: signal,
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);
      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCustomSearches();
      return responseJson;
    } catch (error) {
      console.log(error);
    }
  };

  const getSuppliers = async (term) => {
    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/search?filter-term=${term}&include=categories,quickScanOrder`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      return responseJson;
    } catch (error) {
      console.log(error);
    }
  };

  const postLongList = async (longlist) => {
    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/custom-search-result`,
        {
          signal: signal,
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(longlist)
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      return responseJson;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomSearches();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomSearchContext.Provider
      value={{
        customSearches,
        getCustomSearches,
        getCustomSearch,
        deleteCustomSearch,
        totalSearches,
        getSuppliers,
        response,
        setResponse,
        postLongList,
        loading
      }}
    >
      {props.children}
    </CustomSearchContext.Provider>
  );
};

export default CustomSearchContextProvider;
