import styled from 'styled-components';
import { Button, Card } from 'antd';

////////////////////////////////////
///////////////////////////////////
//////// ANSWER CHALLENGE //////////
///////////////////////////////////
///////////////////////////////////

export const StlyedCard = styled(Card)`
  min-width: 460px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);

  .ant-card-body {
    padding: 2.5rem 2.5rem 1rem 2.5rem;

    h3 {
      margin-bottom: 2rem;
    }
  }
`;

export const CardFooter = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  p {
    margin-bottom: 0;
  }
`;

export const CardButton = styled(Button)`
  height: auto;
  min-width: 150px;
  padding: 0.75rem;
  text-transform: uppercase;
  border-radius: 0;
  font-size: 12px;
  letter-spacing: 1.1px;
`;

////////////////////////////////////
///////////////////////////////////
///////// PRIVATE ROUTE //////////
///////////////////////////////////
///////////////////////////////////

export const SpinWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

////////////////////////////////////
///////////////////////////////////
//////////// SIGN IN //////////////
///////////////////////////////////
///////////////////////////////////

export const CardFooterSignIn = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
  }
`;
