import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Button,
  Row,
  Divider
} from 'antd';
import AsyncSelect from 'react-select/async';
import { CompaniesContext } from '../../contexts/CompaniesContext';
import { defaultFormLayout } from '../../consts/formStyles';
import {
  ajaxErrorFieldValidationProperties,
  formatDate,
  generateBase64FilesFormat
} from '../helpers/formHelpers';
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { normFile } from '../helpers/fileUploadHelpers';
import moment from 'moment';
import { debounce } from 'lodash';

const { Option } = Select;

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 }
  }
};

const customStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: '32px',
    background: 'white',
    boxSizing: 'border-box',
    boxShadow: state.isFocused ? '0 0 0 2px rgba(24, 144, 255, 0.2)' : 'none',
    fontSize: '14px',
    padding: '0px 8px',
    borderRadius: '2px',

    border: state.isFocused ? '1px solid #40a9ff' : `1px solid #d9d9d9`,
    '&:hover': {
      borderColor: state.isFocused ? 'none' : '#40a9ff'
    }
  }),
  input: (base) => ({
    ...base,
    color: '#000',
    margin: 0,
    padding: 0
  }),
  dropdownIndicator: () => ({
    display: 'none'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#C4C4C4',
    fontSize: '14px'
  }),
  // input: (provided) => ({
  //   ...provided,
  //   color: "#0091d4",
  //   fontSize: "16px",
  // }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    zIndex: 10
  })
  // option: (provided) => ({
  //   ...provided,
  //   backgroundColor: "transparent",
  //   padding: "17px",
  //   "&:hover": {
  //     color: "#0091d4",
  //     cursor: "pointer",
  //   },
  // }),
};

const EditCompanyDetails = (props) => {
  const { editCompany, response, setResponse } = useContext(CompaniesContext);

  const { modalVisible, setModalVisible, modal, selectedCompany } = props;
  const [loading, setLoading] = useState(false);
  const [referenceList, setReferenceList] = useState([]);
  const [companyPresentation, setCompanyPresentation] = useState([]);
  const [companyLogo, setCompanyLogo] = useState([]);
  const [companyPhotos, setCompanyPhotos] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [value, setValue] = useState('');

  const certificates = selectedCompany.Certificates
    ? selectedCompany.Certificates.map((certificate) => {
        return {
          name: certificate.name,
          number: certificate.number,
          expiry_date: moment(certificate.expiry_date, 'YYYY-MM-DD')
        };
      })
    : [];

  const [form] = Form.useForm();

  const companyTypes = [
    {
      name: 'supplier',
      displayName: 'Supplier'
    },
    {
      name: 'buyer',
      displayName: 'Buyer'
    }
  ];
  const supplierTypes = [
    {
      name: 'manufacturer-producer',
      displayName: 'Manufacturer/Producer'
    },
    {
      name: 'distributor',
      displayName: 'Distributor'
    },
    {
      name: 'service-provider',
      displayName: 'Service provider'
    },
    {
      value: 'importer',
      displayName: 'Importer'
    }
  ];

  const getAsyncOptions = async (inputValue, callback) => {
    if (!inputValue) return;
    return fetch(
      `${process.env.REACT_APP_AUTOCOMPLETE_API}/address?address=${inputValue}`,
      {
        headers: {
          'x-api-key': 'a5dd0715-6574-4cd7-be77-0d1df3b48ebd',
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      }
    )
      .then(async (res) => {
        const data = await res.json();
        console.log('data:', data);
        if (data.message) callback([]);
        callback([data]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getAsyncOptions(inputText, callback);
    }, 1000),
    []
  );

  const handleInputChange = (value) => {
    setValue(value);
  };

  const handleChange = (value) => {
    if (!value) return;
    setSelectedValue(value);
    form.setFieldsValue({
      company: {
        country_id: value.country,
        city_id: value.city,
        postcode: value.zipCode,
        address: value.fullAddress
      }
    });
  };

  useEffect(() => {
    let references = [];
    let presentation = [];
    let logo = [];
    let photos = [];

    if (typeof selectedCompany.reference_list === 'string') {
      references = [
        {
          name: 'Reference list',
          uid: 1,
          url: selectedCompany.reference_list
        }
      ];
    }

    if (typeof selectedCompany.company_presentation === 'string') {
      presentation = [
        {
          name: 'Presentation',
          uid: 1,
          url: selectedCompany.company_presentation
        }
      ];
    }

    if (typeof selectedCompany.logo === 'string') {
      logo = [
        {
          title: 'Logo',
          uid: 1,
          url: selectedCompany.logo
        }
      ];
    }

    if (selectedCompany.Media) {
      photos = selectedCompany.Media.map((photo) => {
        return {
          uid: photo.id,
          url: photo.path
        };
      });
    }

    setReferenceList(references);
    setCompanyPresentation(presentation);
    setCompanyLogo(logo);
    setCompanyPhotos(photos);
  }, [selectedCompany]);

  const handleCancelPreview = () => setPreviewVisible(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      await generateBase64FilesFormat(file).then(() => {
        file.preview = file.base64;
      });
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleFileChange = async (info, contextSetter) => {
    if (info.file.status === 'removed') {
      let uid = info.file.uid;
      contextSetter(info.fileList.filter((file) => file.uid !== uid));
      return;
    }

    console.log(info);
    info.fileList[info.fileList.length - 1].fileObj = info.file;

    contextSetter(info.fileList);
  };

  const handleBeforeUploadForSingleFileUploads = (file, contextSetter) => {
    contextSetter([file]);
    return false;
  };

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        referenceList.length > 0
          ? (values.company.reference_list = generateFileObj(referenceList)[0])
          : (values.company.reference_list = null);

        companyPresentation.length > 0
          ? (values.company.company_presentation =
              generateFileObj(companyPresentation)[0])
          : (values.company.company_presentation = null);

        companyLogo.length > 0
          ? (values.company.logo = generateFileObj(companyLogo)[0])
          : (values.company.logo = null);

        companyPhotos.length > 0
          ? (values.company.medias = generateFileObj(companyPhotos))
          : (values.company.medias = []);

        values.company.certificates = values.company.certificates.map(
          (certificate) => {
            return {
              name: certificate.name,
              number: certificate.number,
              expiry_date: formatDate(certificate.expiry_date)
            };
          }
        );

        editCompany(values.company, selectedCompany.id);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const generateFileObj = (fileList) => {
    let fileObjArray = [];

    fileList.forEach((file) => {
      fileObjArray.push(file.fileObj);
    });

    return fileObjArray;
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    //There is a some sort of race condition problem going on with initialValues and resetFields() in antd4
    //I have to use this use effect so I can reset form values to new values when form loads. Otherwise form will retain old data.
    if (modalVisible) {
      form.resetFields();
      form.setFieldsValue({
        company: {
          owner_id: selectedCompany.owner_id,
          name: selectedCompany.name,
          industry: selectedCompany.industry,
          parent_company: selectedCompany.parent_company,
          duns_number: selectedCompany.duns_number,
          tax_jurisdiction_code: selectedCompany.tax_jurisdiction_code,
          vat_id: selectedCompany.vat_id,
          short_description: selectedCompany.short_description,
          country_id: selectedCompany?.Country?.name,
          city_id: selectedCompany?.City?.name,
          postcode: selectedCompany.postcode,
          address: selectedCompany.address,
          turnover: selectedCompany.turnover,
          type: selectedCompany.type,
          communication_language: selectedCompany.communication_language,
          email: selectedCompany.email,
          number_of_employees: selectedCompany.number_of_employees,
          phone: selectedCompany.phone,
          supplier_type: selectedCompany.supplier_type,
          certificates: certificates
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  if (modal === 'company-details') {
    return (
      <Modal
        title={`Edit ${selectedCompany.name} company details`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        width="50%"
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Owner UUID"
            name={['company', 'owner_id']}
            {...ajaxErrorFieldValidationProperties(response, 'owner_id')}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Name"
            name={['company', 'name']}
            {...ajaxErrorFieldValidationProperties(response, 'name')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Industry"
            name={['company', 'industry']}
            {...ajaxErrorFieldValidationProperties(response, 'industry')}
          >
            <Input placeholder="Industry" />
          </Form.Item>
          <Form.Item
            label="Parent Company"
            name={['company', 'parent_company']}
            {...ajaxErrorFieldValidationProperties(response, 'parent_company')}
          >
            <Input placeholder="Parent Company" />
          </Form.Item>
          <Form.Item
            label="DUNS Number"
            name={['company', 'duns_number']}
            {...ajaxErrorFieldValidationProperties(response, 'duns_number')}
          >
            <Input placeholder="DUNS Number" />
          </Form.Item>
          <Form.Item
            label="Tax Jurisdiction Number"
            name={['company', 'tax_jurisdiction_code']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'tax_jurisdiction_code'
            )}
          >
            <Input placeholder="Tax Jurisdiction Number" />
          </Form.Item>
          <Form.Item
            label="VAT id"
            name={['company', 'vat_id']}
            {...ajaxErrorFieldValidationProperties(response, 'vat_id')}
          >
            <Input placeholder="VAT id" />
          </Form.Item>
          <Form.Item
            label="Short description"
            name={['company', 'short_description']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'short_description'
            )}
          >
            <Input placeholder="Short description" />
          </Form.Item>
          <Form.Item
            label="Full address"
            name={['company', 'full_address']}
            {...ajaxErrorFieldValidationProperties(response, 'country_id')}
          >
            <AsyncSelect
              styles={customStyles}
              cacheOptions
              defaultOptions
              value={selectedValue}
              getOptionLabel={(e) => e.fullAddress}
              getOptionValue={(e) => e.fullAddress}
              loadOptions={loadOptions}
              noOptionsMessage={() => 'Nothing found'}
              onInputChange={handleInputChange}
              onChange={handleChange}
              placeholder={'Search...'}
            />
          </Form.Item>

          <Form.Item
            label="Country"
            name={['company', 'country_id']}
            {...ajaxErrorFieldValidationProperties(response, 'country_id')}
          >
            <Input placeholder="Country" disabled />
          </Form.Item>
          <Form.Item
            label="City"
            name={['company', 'city_id']}
            {...ajaxErrorFieldValidationProperties(response, 'city_id')}
          >
            <Input placeholder="City" disabled />
          </Form.Item>
          <Form.Item
            label="Postal code"
            name={['company', 'postcode']}
            {...ajaxErrorFieldValidationProperties(response, 'postcode')}
            rules={[
              {
                min: 4,
                message: 'Postal code must be between 4 and 9 characters!'
              },
              {
                max: 9,
                message: 'Postal code must be between 4 and 9 characters!'
              }
            ]}
          >
            <Input placeholder="Postal code" disabled />
          </Form.Item>

          <Form.Item
            label="Address"
            name={['company', 'address']}
            {...ajaxErrorFieldValidationProperties(response, 'address')}
          >
            <Input placeholder="Address" disabled />
          </Form.Item>
          <Form.Item
            label="Turnover"
            name={['company', 'turnover']}
            {...ajaxErrorFieldValidationProperties(response, 'turnover')}
          >
            <Input placeholder="Turnover" />
          </Form.Item>
          <Form.Item
            label="Company type"
            name={['company', 'type']}
            {...ajaxErrorFieldValidationProperties(response, 'type')}
          >
            <Select placeholder="Type">
              {companyTypes.map((type) => {
                return (
                  <Option key={`${type.name}-type`} value={type.name}>
                    {type.displayName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Communication language"
            name={['company', 'communication_language']}
            // {...ajaxErrorFieldValidationProperties(
            //   response,
            //   'communication_language'
            // )}
          >
            <Input placeholder="Communication language" />
          </Form.Item>
          <Form.Item
            label="Email"
            name={['company', 'email']}
            // {...ajaxErrorFieldValidationProperties(response, 'email')}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Number of employees"
            name={['company', 'number_of_employees']}
            // {...ajaxErrorFieldValidationProperties(
            //   response,
            //   'number_of_employees'
            // )}
          >
            <Input placeholder="Number of employees" />
          </Form.Item>
          <Form.Item
            label="Phone"
            name={['company', 'phone']}
            {...ajaxErrorFieldValidationProperties(response, 'phone')}
          >
            <Input placeholder="Phone" />
          </Form.Item>
          <Form.Item
            label="Supplier type"
            name={['company', 'supplier_type']}
            {...ajaxErrorFieldValidationProperties(response, 'supplier_type')}
          >
            <Select placeholder="Supplier type">
              {supplierTypes.map((type) => {
                return (
                  <Option key={`${type.name}-supplier-type`} value={type.name}>
                    {type.displayName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Reference list"
            name={['company', 'reference_list']}
            {...ajaxErrorFieldValidationProperties(response, 'reference_list')}
          >
            <Upload
              fileList={referenceList}
              onChange={(info) => handleFileChange(info, setReferenceList)}
              beforeUpload={(file) =>
                handleBeforeUploadForSingleFileUploads(file, setReferenceList)
              }
            >
              <Button>
                <UploadOutlined /> Click to upload reference list
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Company Presentation"
            name={['company', 'company_presentation']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'company_presentation'
            )}
          >
            <Upload
              fileList={companyPresentation}
              onChange={(info) =>
                handleFileChange(info, setCompanyPresentation)
              }
              beforeUpload={(file) =>
                handleBeforeUploadForSingleFileUploads(
                  file,
                  setCompanyPresentation
                )
              }
            >
              <Button>
                <UploadOutlined /> Click to upload reference list
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Company Logo"
            name={['company', 'logo']}
            {...ajaxErrorFieldValidationProperties(response, 'logo')}
          >
            <Upload
              listType="picture-card"
              onPreview={handlePreview}
              fileList={companyLogo}
              getValueFromEvent={normFile}
              onChange={(info) => handleFileChange(info, setCompanyLogo)}
              beforeUpload={(file) =>
                handleBeforeUploadForSingleFileUploads(file, setCompanyLogo)
              }
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Company Photos"
            name={['company', 'photos']}
            {...ajaxErrorFieldValidationProperties(response, 'photos')}
          >
            <Upload
              beforeUpload={() => false}
              listType="picture-card"
              onPreview={handlePreview}
              onChange={(info) => handleFileChange(info, setCompanyPhotos)}
              valuePropName="fileList"
              getValueFromEvent={normFile}
              fileList={companyPhotos}
              multiple={true}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </Form.Item>
          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={handleCancelPreview}
          >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
          <Divider orientation="left">Certificates</Divider>
          <Form.List name={['company', 'certificates']}>
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => {
                  return (
                    <Form.Item
                      {...formItemLayoutWithOutLabel}
                      required={false}
                      key={`certificate-${index}-${field.key}`}
                    >
                      <Form.Item
                        fieldKey={`${field.fieldKey}-name`}
                        isListField={field.isListField}
                        key={`${field.fieldKey}-name`}
                        validateTrigger={['onChange', 'onBlur']}
                        name={[index, 'name']}
                        noStyle
                      >
                        <Input placeholder="Name" style={{ width: '60%' }} />
                      </Form.Item>
                      <Form.Item
                        fieldKey={`${field.fieldKey}-number`}
                        isListField={field.isListField}
                        key={`${field.fieldKey}-number`}
                        validateTrigger={['onChange', 'onBlur']}
                        name={[index, 'number']}
                        noStyle
                      >
                        <Input placeholder="Number" style={{ width: '60%' }} />
                      </Form.Item>
                      <Form.Item
                        fieldKey={`${field.fieldKey}-expiry-date`}
                        isListField={field.isListField}
                        key={`${field.fieldKey}-expiry-date`}
                        validateTrigger={['onChange', 'onBlur']}
                        name={[index, 'expiry_date']}
                        noStyle
                      >
                        <DatePicker
                          format="DD/MM/YYYY"
                          placeholder="Expiry date"
                          style={{ width: '60%' }}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  );
                })}
                <Row>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Row>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default EditCompanyDetails;
