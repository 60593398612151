import React, { useContext } from 'react';
import { Layout, Dropdown, Avatar, Menu } from 'antd';
import styled from 'styled-components';

import { AdminContext } from '../../contexts/AdminContext';
import { UserOutlined } from '@ant-design/icons';

const StyledHeader = styled(Layout.Header)`
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
`;

const StyledHeaderInner = styled.div`
  height: 100%;
  display: flex;
`;

const StyledDropdown = styled(Dropdown)`
  cursor: pointer;

  span {
    cursor: pointer;
  }
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 0.5rem;
`;

const Header = () => {
  const { admin, handleSignOut } = useContext(AdminContext);

  const menu = (
    <Menu>
      <Menu.Item>
        <div onClick={handleSignOut}>
          <span>Sign out</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <StyledHeader>
      <StyledHeaderInner>
        <StyledDropdown overlay={menu} placement="bottomRight">
          <span
            style={{
              color: 'rgba(0, 0, 0, 0.65)'
            }}
          >
            <StyledAvatar icon={<UserOutlined />} />
            {admin.attributes.email}
          </span>
        </StyledDropdown>
      </StyledHeaderInner>
    </StyledHeader>
  );
};

export default Header;
