import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Checkbox } from 'antd';

import { UsersContext } from '../../contexts/UsersContext';
import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';
import { defaultFormLayout } from '../../consts/formStyles';
import { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';

const customStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: '32px',
    background: 'white',
    boxSizing: 'border-box',
    boxShadow: state.isFocused ? '0 0 0 2px rgba(24, 144, 255, 0.2)' : 'none',
    fontSize: '14px',
    padding: '0px 8px',
    borderRadius: '2px',

    border: state.isFocused ? '1px solid #40a9ff' : `1px solid #d9d9d9`,
    '&:hover': {
      borderColor: state.isFocused ? 'none' : '#40a9ff'
    }
  }),
  input: (base) => ({
    ...base,
    color: '#000',
    margin: 0,
    padding: 0
  }),
  dropdownIndicator: () => ({
    display: 'none'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#C4C4C4',
    fontSize: '14px'
  }),
  // input: (provided) => ({
  //   ...provided,
  //   color: "#0091d4",
  //   fontSize: "16px",
  // }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    zIndex: 10
  })
  // option: (provided) => ({
  //   ...provided,
  //   backgroundColor: "transparent",
  //   padding: "17px",
  //   "&:hover": {
  //     color: "#0091d4",
  //     cursor: "pointer",
  //   },
  // }),
};

const EditUser = (props) => {
  const { editUser, response, setResponse } = useContext(UsersContext);
  const { modalVisible, setModalVisible, modal, selectedUser } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [paid, setPaid] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [value, setValue] = useState('');

  useEffect(() => {
    if (selectedUser?.paid === true) {
      setPaid(true);
    } else {
      setPaid(false);
    }

    if (selectedUser?.is_admin === true) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [selectedUser]);

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        values.user.paid = paid ? true : false;
        values.user.is_admin = isAdmin ? true : false;
        editUser(values.user, selectedUser.sub);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({
      progress: false
    });
    setLoading(false);
    closeModal();
  };

  const getAsyncOptions = async (inputValue, callback) => {
    if (!inputValue) return;
    return fetch(
      `${process.env.REACT_APP_AUTOCOMPLETE_API}/address?address=${inputValue}`,
      {
        headers: {
          'x-api-key': process.env.REACT_APP_AUTOCOMPLETE_API_KEY,
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      }
    ).then(async (res) => {
      const data = await res.json();
      if (data.message) callback([]);
      callback([data]);
    });
  };

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getAsyncOptions(inputText, callback);
    }, 1000),
    []
  );

  const handleInputChange = (value) => {
    setValue(value);
  };

  const handleChange = (value) => {
    console.log(value);
    if (!value) return;
    setSelectedValue(value);
    console.log(value);
    form.setFieldsValue({
      company: {
        country: value.country,
        city: value.city,
        zip: value.zipCode,
        street: value.fullAddress
      }
    });
  };

  useEffect(() => {
    //There is a some sort of race condition problem going on with initialValues and resetFields() in antd4
    //I have to use this use effect so I can reset form values to new values when form loads. Otherwise form will retain old data.
    if (modalVisible) {
      form.resetFields();
      form.setFieldsValue({
        user: {
          name: selectedUser.name,
          street: selectedUser.street || '',
          zip: selectedUser.zip,
          city: selectedUser.city,
          country: selectedUser.country,
          discipline: selectedUser.discipline || '',
          industry: selectedUser.industry || '',
          job_function: selectedUser.job_function || '',
          job_level: selectedUser.job_level || '',
          mobile_phone: selectedUser.mobile_phone || '',
          company_name: selectedUser.company_name || '',
          business_name: selectedUser.business_name || '',
          business_country: selectedUser.business_country || '',
          business_city: selectedUser.business_city || '',
          business_vat: selectedUser.business_vat || '',
          business_address: selectedUser.business_address || ''
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  useEffect(() => {
    if (modal === 'edit') {
      if (response.code)
        response.errors = {
          email: response.code
        };

      if (!response.progress && !response.code) {
        closeModal();
      }

      if (!response.progress) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'edit') {
    return (
      <Modal
        title={`Edit ${selectedUser.name}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Name"
            name={['user', 'name']}
            {...ajaxErrorFieldValidationProperties(response, 'name')}
          >
            <Input placeholder="Name" disabled />
          </Form.Item>
          <Form.Item
            label="Full address"
            name={['company', 'full_address']}
            {...ajaxErrorFieldValidationProperties(response, 'country_id')}
          >
            <AsyncSelect
              styles={customStyles}
              cacheOptions
              defaultOptions
              value={selectedValue}
              getOptionLabel={(e) => e.fullAddress}
              getOptionValue={(e) => e.fullAddress}
              loadOptions={loadOptions}
              noOptionsMessage={() => 'Nothing found'}
              onInputChange={handleInputChange}
              onChange={handleChange}
              placeholder={'Search...'}
            />
          </Form.Item>

          <Form.Item
            label="Country"
            name={['company', 'country']}
            {...ajaxErrorFieldValidationProperties(response, 'country_id')}
          >
            <Input placeholder="Country" disabled />
          </Form.Item>
          <Form.Item
            label="City"
            name={['company', 'city']}
            {...ajaxErrorFieldValidationProperties(response, 'city_id')}
          >
            <Input placeholder="City" disabled />
          </Form.Item>
          <Form.Item
            label="Postal code"
            name={['company', 'zip']}
            {...ajaxErrorFieldValidationProperties(response, 'postcode')}
            rules={[
              {
                min: 4,
                message: 'Postal code must be between 4 and 9 characters!'
              },
              {
                max: 9,
                message: 'Postal code must be between 4 and 9 characters!'
              }
            ]}
          >
            <Input placeholder="Postal code" disabled />
          </Form.Item>

          <Form.Item
            label="Street"
            name={['company', 'street']}
            {...ajaxErrorFieldValidationProperties(response, 'address')}
          >
            <Input placeholder="Street" disabled />
          </Form.Item>

          <Form.Item
            label="Discipline"
            name={['user', 'discipline']}
            {...ajaxErrorFieldValidationProperties(response, 'discipline')}
          >
            <Input placeholder="Discipline" />
          </Form.Item>
          <Form.Item
            label="Industry"
            name={['user', 'industry']}
            {...ajaxErrorFieldValidationProperties(response, 'industry')}
          >
            <Input placeholder="Industry" />
          </Form.Item>
          <Form.Item
            label="Job function"
            name={['user', 'job_function']}
            {...ajaxErrorFieldValidationProperties(response, 'job_function')}
          >
            <Input placeholder="Job function" />
          </Form.Item>
          <Form.Item
            label="Job level"
            name={['user', 'job_level']}
            {...ajaxErrorFieldValidationProperties(response, 'job_level')}
          >
            <Input placeholder="Job level" />
          </Form.Item>
          <Form.Item
            label="Mobile phone"
            name={['user', 'mobile_phone']}
            {...ajaxErrorFieldValidationProperties(response, 'mobile_phone')}
          >
            <Input placeholder="Mobile phone" />
          </Form.Item>
          <Form.Item
            label="Company name"
            name={['user', 'company_name']}
            {...ajaxErrorFieldValidationProperties(response, 'company_name')}
          >
            <Input placeholder="Company name" />
          </Form.Item>
          <Form.Item
            label="Business name"
            name={['user', 'business_name']}
            {...ajaxErrorFieldValidationProperties(response, 'business_name')}
          >
            <Input placeholder="Business name" />
          </Form.Item>
          <Form.Item
            label="Business country"
            name={['user', 'business_country']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'business_country'
            )}
          >
            <Input placeholder="Business country" />
          </Form.Item>
          <Form.Item
            label="Business city"
            name={['user', 'business_city']}
            {...ajaxErrorFieldValidationProperties(response, 'business_city')}
          >
            <Input placeholder="Business city" />
          </Form.Item>
          <Form.Item
            label="Business vat code"
            name={['user', 'business_vat']}
            {...ajaxErrorFieldValidationProperties(response, 'business_vat')}
          >
            <Input placeholder="Business vat code" />
          </Form.Item>
          <Form.Item
            label="Business address"
            name={['user', 'business_address']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'business_address'
            )}
          >
            <Input placeholder="Business address" />
          </Form.Item>

          <Form.Item
            label="Paid"
            name={['user', 'paid']}
            {...ajaxErrorFieldValidationProperties(response, 'paid')}
          >
            <Checkbox
              onChange={(e) => setPaid(e.target.checked)}
              checked={paid}
            />
          </Form.Item>

          <Form.Item
            label="Is admin"
            name={['user', 'is_admin']}
            {...ajaxErrorFieldValidationProperties(response, 'is_admin')}
          >
            <Checkbox
              onChange={(e) => setIsAdmin(e.target.checked)}
              checked={isAdmin || false}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default EditUser;
