import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

let searchInput;

const getColumnSearchProps = (dataIndex, dataFieldToSearch) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => {
          handleSearch(confirm);
        }}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => {
          handleSearch(confirm);
        }}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button
        onClick={() => {
          handleReset(clearFilters, confirm);
        }}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: () => true,
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.select());
    }
  }
});

const handleSearch = (confirm) => {
  confirm();
};

const handleReset = (clearFilters, confirm) => {
  clearFilters();
  confirm();
};

export default getColumnSearchProps;
