import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Select } from 'antd';

import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';
import { defaultFormLayout } from '../../consts/formStyles';
import { MessagesContext } from '../../contexts/MessagesContext';

const { Option } = Select;
const { TextArea } = Input;

const EditMessage = (props) => {
  const { editMessage, response, setResponse } = useContext(MessagesContext);
  const { modalVisible, setModalVisible, modal, selectedMessage } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        editMessage(selectedMessage, values.message);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    //There is a some sort of race condition problem going on with initialValues and resetFields() in antd4
    //I have to use this use effect so I can reset form values to new values when form loads. Otherwise form will retain old data.
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  useEffect(() => {
    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'edit') {
    return (
      <Modal
        title={`Edit ${selectedMessage.product}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Product"
            name={['message', 'product']}
            {...ajaxErrorFieldValidationProperties(response, 'product')}
            rules={[{ required: true, message: 'Please input a product!' }]}
            initialValue={selectedMessage.product}
          >
            <Input placeholder="Product" />
          </Form.Item>

          <Form.Item
            label="Location"
            name={['message', 'productLocation']}
            {...ajaxErrorFieldValidationProperties(response, 'productLocation')}
            rules={[{ required: true, message: 'Please input a location!' }]}
            initialValue={selectedMessage.productLocation}
          >
            <Input placeholder="Location" />
          </Form.Item>

          <Form.Item
            label="Response"
            name={['message', 'fullResponse']}
            {...ajaxErrorFieldValidationProperties(response, 'fullResponse')}
            rules={[{ required: true, message: 'Please input a response!' }]}
            initialValue={
              typeof selectedMessage.fullResponse === 'object'
                ? selectedMessage.fullResponse.response
                : selectedMessage.fullResponse
            }
          >
            <TextArea
              placeholder="fullResponse"
              autoSize={{ minRows: 2, maxRows: 8 }}
            />
          </Form.Item>

          {/* <Form.Item
            label="Scan"
            name={['message', 'quickscan']}
            {...ajaxErrorFieldValidationProperties(response, 'quickscan')}
            rules={[{ required: true, message: 'Please select a scan!' }]}
            initialValue={selectedMessage.quickscan}
          >
            <Select placeholder="Scan">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Assesment"
            name={['message', 'selfAssesment']}
            {...ajaxErrorFieldValidationProperties(response, 'selfAssesment')}
            rules={[{ required: true, message: 'Please select a assesment!' }]}
            initialValue={selectedMessage.selfAssesment}
          >
            <Select placeholder="Assesment">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Audit"
            name={['message', 'audit']}
            {...ajaxErrorFieldValidationProperties(response, 'audit')}
            rules={[{ required: true, message: 'Please select a audit!' }]}
            initialValue={selectedMessage.audit}
          >
            <Select placeholder="Audit">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item> */}
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default EditMessage;
