import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { MessagesContext } from '../../contexts/MessagesContext';

const StyledBold = styled.span`
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 22px;
`;

const DeleteMessage = (props) => {
  const { deleteMessage, response, setResponse } = useContext(MessagesContext);
  const { modalVisible, setModalVisible, modal, selectedMessage } = props;
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleOk = () => {
    setLoading(true);
    deleteMessage(selectedMessage.id);
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    if (Object.entries(response).length !== 0 && !response.errors) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'delete') {
    return (
      <Modal
        title={`Delete ${selectedMessage.product}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <p>
          This operation will permanently delete{' '}
          <StyledBold>
            {selectedMessage.product
              ? selectedMessage.product.toUpperCase()
              : null}
          </StyledBold>
          !
        </p>
        <p>Are you sure you want to continue?</p>
      </Modal>
    );
  } else {
    return null;
  }
};

export default DeleteMessage;
