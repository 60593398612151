import React, { useState, useEffect, createContext, useContext } from 'react';
import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';

import { APP_NAME } from '../consts/globals';
import { AuthContext } from './AuthContext';
import {
  generateFiltersQueryString,
  generateSortersQueryString
} from 'components/helpers/tableOrderAndSortHelpers';

export const UsersContext = createContext();

const UsersContextProvider = (props) => {
  const { cognitoUser } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [response, setResponse] = useState({
    progress: false
  });
  const [loading, setLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState(null);

  const customAttributes = [
    'street',
    'zip',
    'city',
    'country',
    'business_name',
    'business_country',
    'business_city',
    'business_zip',
    'business_address',
    'business_vat',
    'paid'
  ];

  const getUsers = async (pageSize = 10, pageNumber = 1, filters, sorter) => {
    setLoading(true);
    try {
      const appendFilters = generateFiltersQueryString(filters);
      const appendSorter = generateSortersQueryString(sorter);
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/system/all-users?page-size=${pageSize}&page-number=${pageNumber}${appendFilters}${appendSorter}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            Authorization:
              'Bearer ' + cognitoUser.signInUserSession.idToken.jwtToken
          }
        }
      );
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      setResponse({ ...responseJson, progress: false });
      setTotalUsers(responseJson.meta?.total);
      setUsers(responseJson.data);
      setLoading(false);
    } catch (error) {
      setResponse({ code: error.message, progress: false });
    }
  };

  const getGroups = () => {
    const params = {
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID
    };

    const cognito = new AWS.CognitoIdentityServiceProvider();
    cognito.listGroups(params, function (error, data) {
      if (error) {
        console.log(error);
      } else {
        const filteredGroups = data.Groups.filter((group) => {
          return (
            group.GroupName.includes(APP_NAME) &&
            group.GroupName !== 'yvoo-auditor'
          );
        });
        setGroups(filteredGroups);
      }
    });
  };

  const createUser = async (user) => {
    setResponse({
      progress: true
    });

    let params = {
      username: user.email,
      password: getRandomString(30),
      attributes: {
        email: user.email
      }
    };

    const existingUserAttributes = customAttributes.filter((attribute) => {
      return user[attribute];
    });

    existingUserAttributes.forEach((param) => {
      params.attributes['custom:' + param] = user[param];
    });

    try {
      await Auth.signUp(params);
      getUsers();
      setResponse({ progress: false });
    } catch (error) {
      setResponse({ code: error.message, progress: false });
    }
  };

  const getRandomString = (bytes) => {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join('');
  };

  const intToHex = (nr) => {
    return nr.toString(16).padStart(2, '0');
  };

  const getUserGroups = async (user) => {
    const params = {
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      Username: user.username
    };

    const cognito = new AWS.CognitoIdentityServiceProvider();
    cognito.adminListGroupsForUser(params, function (error, data) {
      if (error) {
        console.log(error);
      } else {
        setUserGroups(data.Groups);
      }
    });
  };

  const changeUserGroup = async (user) => {
    setResponse({ requestFor: 'change group', requestInProgress: true });

    const removeGroupParams = {
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      Username: user.username,
      GroupName: userGroups[0] ? userGroups[0].GroupName : user.group
    };

    let changeGroupParams = {
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      Username: user.username,
      GroupName: user.group
    };

    const cognito = new AWS.CognitoIdentityServiceProvider();

    cognito.adminRemoveUserFromGroup(removeGroupParams, function (error, data) {
      if (error) {
        setResponse({
          code: error.message,
          requestFor: 'change group',
          requestInProgress: false
        });
      } else {
        setResponse({
          ...data,
          requestFor: 'change group',
          requestInProgress: true
        });

        cognito.adminAddUserToGroup(changeGroupParams, function (error, data) {
          if (error) {
            setResponse({
              ...error,
              requestFor: 'change group',
              requestInProgress: false
            });
          } else {
            getUsers();
            setResponse({
              ...data,
              requestFor: 'change group',
              requestInProgress: false
            });
          }
          getUserGroups(user);
        });
      }
    });
  };

  const removeUserGroup = async (user, group) => {
    setResponse({ requestFor: 'remove group', requestInProgress: true });

    let params = {
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      Username: user.username,
      GroupName: group
    };

    const cognito = new AWS.CognitoIdentityServiceProvider();
    cognito.adminRemoveUserFromGroup(params, function (error, data) {
      if (error) {
        setResponse({
          code: error.message,
          requestFor: 'remove group',
          requestInProgress: false
        });
      } else {
        setResponse({
          ...data,
          requestFor: 'remove group',
          requestInProgress: false
        });
        getUserGroups(user);
      }
    });
  };

  const editUser = async (user, selectedUser) => {
    setResponse({
      progress: true
    });
    // let params = {
    //   UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    //   Username: user.email,
    //   UserAttributes: [
    //     {
    //       Name: 'email',
    //       Value: user.email
    //     }
    //   ]
    // };

    // const existingUserAttributes = customAttributes.filter((attribute) => {
    //   return user[attribute];
    // });

    // existingUserAttributes.forEach((param) => {
    //   params.UserAttributes.push({
    //     Name: 'custom:' + param,
    //     Value: user[param]
    //   });
    // });

    // const cognito = new AWS.CognitoIdentityServiceProvider();
    // cognito.adminUpdateUserAttributes(params, function (error, data) {
    //   if (error) {
    //     setResponse({ code: error.message, progress: false });
    //   } else {
    //     getUsers();
    //     setResponse({ ...data, progress: false });
    //   }
    // });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/system/user/${selectedUser}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            Authorization:
              'Bearer ' + cognitoUser.signInUserSession.idToken.jwtToken
          },
          body: JSON.stringify(user)
        }
      );
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      setResponse({ ...responseJson, progress: false });

      getUsers();
    } catch (error) {
      setResponse({ code: error.message, progress: false });
    }
  };

  const deleteUser = async (user) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/system/user/${user.sub}`,
        {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            Authorization:
              'Bearer ' + cognitoUser.signInUserSession.idToken.jwtToken
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      setResponse({ ...responseJson, progress: false });

      let params = {
        UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
        Username: user.username
      };

      const cognito = new AWS.CognitoIdentityServiceProvider();
      cognito.adminDeleteUser(params, function (error, data) {
        if (error) {
          setResponse({ code: error.message, progress: false });
        } else {
          getUsers();
          setResponse({ ...data, progress: false });
        }
      });
      getUsers();
    } catch (error) {
      setResponse({ code: error.message, progress: false });
    }
  };

  useEffect(() => {
    getUsers();
    getGroups();
  }, []);

  return (
    <UsersContext.Provider
      value={{
        createUser,
        editUser,
        deleteUser,
        groups,
        getUsers,
        totalUsers,
        userGroups,
        getUserGroups,
        changeUserGroup,
        removeUserGroup,
        response,
        setResponse,
        users,
        getUsers,
        loading
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
};

export default UsersContextProvider;
