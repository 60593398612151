import React, { useState, useEffect, createContext, useContext } from 'react';

import { AuthContext } from './AuthContext';
import { API_ENDPOINT } from '../consts/globals';
import {
  generateFiltersQueryString,
  generateSortersQueryString
} from 'components/helpers/tableOrderAndSortHelpers';

export const CountriesContext = createContext();

const CountriesContextProvider = (props) => {
  const { authFetch } = useContext(AuthContext);
  const [countries, setCountries] = useState([]);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [totalCountries, setTotalCountries] = useState(null);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const getCountries = async (
    pageSize = 10,
    pageNumber = 1,
    filters,
    sorter
  ) => {
    setLoading(true);

    try {
      const appendFilters = generateFiltersQueryString(filters);
      const appendSorter = generateSortersQueryString(sorter);
      const response = await authFetch(`${API_ENDPOINT}/system/countries`, {
        signal: signal,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setCountries(responseJson.data);
      setTotalCountries(responseJson.meta?.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const createCountry = async (country) => {
    setResponse({});

    let image64;

    if (country.flag) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(country.flag[0].originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }

    try {
      const transformedCountry = {
        ...country,
        flag: await image64
      };

      const response = await authFetch(`${API_ENDPOINT}/system/countries`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(transformedCountry)
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCountries();
    } catch (error) {
      console.log(error);
    }
  };

  const editCountry = async (country, id) => {
    setResponse({});

    let image64;
    console.log(country.flag);
    if (country.flag && country.flag.length > 0) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(country.flag[0]?.originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }

    try {
      const { flag, ...transformedCountry } = country;

      if (country.flag && country.flag.length > 0) {
        transformedCountry.flag = await image64;
      }

      const response = await authFetch(
        `${API_ENDPOINT}/system/countries/${id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(transformedCountry)
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCountries();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCountry = async (id) => {
    setResponse({});
    try {
      const response = await authFetch(
        `${API_ENDPOINT}/system/countries/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getCountries();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCountries();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CountriesContext.Provider
      value={{
        countries,
        getCountries,
        totalCountries,
        createCountry,
        editCountry,
        deleteCountry,
        response,
        setResponse,
        loading
      }}
    >
      {props.children}
    </CountriesContext.Provider>
  );
};

export default CountriesContextProvider;
