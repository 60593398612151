import React, { useContext } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import { APP_NAME } from '../consts/globals';

import { AdminContext } from '../contexts/AdminContext';
import Sidebar from './shared/Sidebar';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Welcome from './Welcome';
import UsersContextProvider from '../contexts/UsersContext';
import UsersList from '../components/users/UsersList';
import CountriesContextProvider from '../contexts/CountriesContext';
import CountriesList from '../components/countries/CountriesList';
import CitiesContextProvider from '../contexts/CitiesContext';
import CitiesList from '../components/cities/CitiesList';
import CommunitiesContextProvider from '../contexts/CommunitiesContext';
import NewsContextProvider from '../contexts/NewsContext';
import NewsList from '../components/news/NewsList';
import InvoicesContextProvider from '../contexts/InvoicesContext';
import InvoicesList from './invoices/InvoicesList';
import LegalContextProvider from '../contexts/LegalContext';
import LegalList from './legal/LegalList';
import NotFound from './NotFound';
import CompaniesList from './companies/CompaniesList';
import QuickscanList from './admin-quickscans/QuickscanList';
import CompaniesContextProvider from '../contexts/CompaniesContext';
import CustomSearchContextProvider from '../contexts/CustomSearchContext';
import CustomSearchList from './custom-search/CustomSearchList';
import CategorySegmentsContextProvider from '../contexts/CategorySegmentsContext';
import CreateCategory from './categories/CreateCategory';
import CategoryFamiliesContextProvider from '../contexts/CategoryFamiliesContext';
import CategoryClassesContextProvider from '../contexts/CategoryClassesContext';
import CategoryCommoditiesContextProvider from '../contexts/CategoryCommoditiesContext';
import AdminQuickscansContextProvider from '../contexts/AdminQuickscansContext';
import MessagesContextProvider from '../contexts/MessagesContext';
import MessagesList from './messages/MessagesList';

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledContent = styled(Content)`
  margin: 1.5rem;
`;

const StyledWrapper = styled.div`
  padding: 1.5rem;
  background: #fff;
  position: relative;
`;

const Home = () => {
  const { userRole } = useContext(AdminContext);

  return (
    <div>
      <StyledLayout>
        <Sidebar />
        <Layout>
          <Header />
          <StyledContent>
            <StyledWrapper>
              {(() => {
                switch (userRole) {
                  case `${APP_NAME}-admin`:
                    return (
                      <Switch>
                        <Route path="/users">
                          <UsersContextProvider>
                            <UsersList />
                          </UsersContextProvider>
                        </Route>
                        <Route path="/countries">
                          <CountriesContextProvider>
                            <CountriesList />
                          </CountriesContextProvider>
                        </Route>
                        <Route path="/cities">
                          <CountriesContextProvider>
                            <CitiesContextProvider>
                              <CitiesList />
                            </CitiesContextProvider>
                          </CountriesContextProvider>
                        </Route>
                        <Route path="/news">
                          <CountriesContextProvider>
                            <CitiesContextProvider>
                              <CommunitiesContextProvider>
                                <NewsContextProvider>
                                  <NewsList />
                                </NewsContextProvider>
                              </CommunitiesContextProvider>
                            </CitiesContextProvider>
                          </CountriesContextProvider>
                        </Route>
                        <Route path="/invoices">
                          <InvoicesContextProvider>
                            <InvoicesList />
                          </InvoicesContextProvider>
                        </Route>
                        <Route path="/legal">
                          <LegalContextProvider>
                            <LegalList />
                          </LegalContextProvider>
                        </Route>
                        <Route path="/companies">
                          <CountriesContextProvider>
                            <CitiesContextProvider>
                              <CompaniesContextProvider>
                                <CategorySegmentsContextProvider>
                                  <CategoryFamiliesContextProvider>
                                    <CategoryClassesContextProvider>
                                      <CategoryCommoditiesContextProvider>
                                        <CompaniesList />
                                      </CategoryCommoditiesContextProvider>
                                    </CategoryClassesContextProvider>
                                  </CategoryFamiliesContextProvider>
                                </CategorySegmentsContextProvider>
                              </CompaniesContextProvider>
                            </CitiesContextProvider>
                          </CountriesContextProvider>
                        </Route>
                        <Route path="/quickscans">
                          <CountriesContextProvider>
                            <CitiesContextProvider>
                              <AdminQuickscansContextProvider>
                                <CategorySegmentsContextProvider>
                                  <CategoryFamiliesContextProvider>
                                    <CategoryClassesContextProvider>
                                      <CategoryCommoditiesContextProvider>
                                        <QuickscanList />
                                      </CategoryCommoditiesContextProvider>
                                    </CategoryClassesContextProvider>
                                  </CategoryFamiliesContextProvider>
                                </CategorySegmentsContextProvider>
                              </AdminQuickscansContextProvider>
                            </CitiesContextProvider>
                          </CountriesContextProvider>
                        </Route>
                        <Route path="/custom-search">
                          <CountriesContextProvider>
                            <CitiesContextProvider>
                              <CompaniesContextProvider>
                                <CustomSearchContextProvider>
                                  <CustomSearchList />
                                </CustomSearchContextProvider>
                              </CompaniesContextProvider>
                            </CitiesContextProvider>
                          </CountriesContextProvider>
                        </Route>
                        <Route path="/categories">
                          <CategorySegmentsContextProvider>
                            <CategoryFamiliesContextProvider>
                              <CategoryClassesContextProvider>
                                <CategoryCommoditiesContextProvider>
                                  <CreateCategory />
                                </CategoryCommoditiesContextProvider>
                              </CategoryClassesContextProvider>
                            </CategoryFamiliesContextProvider>
                          </CategorySegmentsContextProvider>
                        </Route>
                        <Route path="/messages">
                          <MessagesContextProvider>
                            <MessagesList />
                          </MessagesContextProvider>
                        </Route>
                        <Route path="/" exact>
                          <Redirect to="/custom-search" />
                        </Route>
                        <Route component={NotFound} />
                      </Switch>
                    );

                  case `${APP_NAME}-editor`:
                    return (
                      <Switch>
                        <Route path="/" exact>
                          <Welcome />
                        </Route>
                        <Route path="/countries">
                          <CountriesContextProvider>
                            <CountriesList />
                          </CountriesContextProvider>
                        </Route>
                        <Route component={NotFound} />
                      </Switch>
                    );

                  default:
                    return (
                      <Switch>
                        <Route path="/" exact>
                          <Welcome />
                        </Route>
                        <Route path="/countries">
                          <CountriesContextProvider>
                            <CountriesList />
                          </CountriesContextProvider>
                        </Route>
                        <Route component={NotFound} />
                      </Switch>
                    );
                }
              })()}
            </StyledWrapper>
          </StyledContent>
          <Footer />
        </Layout>
      </StyledLayout>
    </div>
  );
};

export default Home;
