/**
 * @typedef {import('antd/lib/table/interface').FilterValue} FilterValue
 * @typedef {import('antd/lib/table/interface').SorterResult} SorterResult
 */

/**
 * Generates the query string for searching in the form of:
 * &filter[filterName]=value&...
 *
 * @param {Record<string, FilterValue>} filters
 * @returns {string}
 */
export function generateFiltersQueryString(filters) {
  return filters
    ? Object.keys(filters)
        .map((filterProp) => {
          if (filters[filterProp] === null) return '';
          return `&filter[${filterProp}]=${filters[filterProp][0]}`;
        })
        .join('')
    : '';
}

/**
 * Returns the query string for sorting/ordering in the form of:
 * &sort=field,-field2,...
 *
 * @param {SorterResult | SorterResult[]} sorters
 * @returns {string}
 */
export function generateSortersQueryString(sorters) {
  return sorters && Object.keys(sorters).length > 0 && sorters.order
    ? `&sort=${sorters.order === 'descend' ? '-' : ''}${
        typeof sorters.column?.sorter === 'string'
          ? sorters.column?.sorter
          : sorters.field
      }`
    : '';
}
