export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const transformFileToBase64 = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return reader.result;
};
