import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Divider, Button } from 'antd';
import { Link } from 'react-router-dom';
import { UsersContext } from '../../contexts/UsersContext';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import ChangeUserGroup from './ChangeUserGroup';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  UserOutlined
} from '@ant-design/icons';
import columnSearch from '../column-search/CustomColumnSearch';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const UsersList = () => {
  const { getUsers, totalUsers, users, getUserGroups, loading } =
    useContext(UsersContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');

  const columns = [
    {
      title: 'Contact',
      dataIndex: 'email',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('email', getUsers, 'email')
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('name', getUsers, 'name')
    },
    {
      title: 'Type',
      // not default behavior to sort by sorter: string, but will get passed down to the sorting helper
      // see the code for generateSortersQueryString(sorter) to see why this is used as a string
      // cannot use dataIndex here because this is a custom derived field
      sorter: 'is_buyer',
      sortDirections: ['descend', 'ascend'],
      render: (user) => {
        let userTypeString = 'None';
        if (user.is_buyer) {
          userTypeString = 'Buyer';
        } else if (user.is_supplier) {
          userTypeString = 'Supplier';
        }

        return `${userTypeString}${user.is_admin ? ' (ADMIN)' : ''}`;
      }
    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (paid) => <>{paid ? <span>Paid</span> : <span>Not paid</span>}</>
    },
    {
      title: 'Memberships',
      render: (user) => (
        <Link to={'/memberships/' + user.username}>
          <span>View</span>
        </Link>
      )
    },
    {
      title: 'Action',
      render: (user) => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('group');
              setSelectedUser(user);
              getUserGroups(user);
            }}
          >
            <UserOutlined />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('edit');
              setSelectedUser(user);
            }}
          >
            <EditOutlined />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('delete');
              setSelectedUser(user);
            }}
          >
            <DeleteOutlined />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        />
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={(user) => user.id}
        dataSource={users}
        pagination={{ total: totalUsers }}
        scroll={{ x: 'max-content' }}
        loading={loading}
        onChange={(pagination, filters, sorter) => {
          getUsers(pagination.pageSize, pagination.current, filters, sorter);
        }}
      />
      <CreateUser
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <ChangeUserGroup
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedUser={selectedUser}
      />
      <EditUser
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedUser={selectedUser}
      />
      <DeleteUser
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedUser={selectedUser}
      />
    </Fragment>
  );
};

export default UsersList;
