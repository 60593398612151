import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Select } from 'antd';

import { CompaniesContext } from '../../contexts/CompaniesContext';
import { defaultFormLayout } from '../../consts/formStyles';
import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';

const { Option } = Select;

const customStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: '32px',
    background: 'white',
    boxSizing: 'border-box',
    boxShadow: state.isFocused ? '0 0 0 2px rgba(24, 144, 255, 0.2)' : 'none',
    fontSize: '14px',
    padding: '0px 8px',
    borderRadius: '2px',

    border: state.isFocused ? '1px solid #40a9ff' : `1px solid #d9d9d9`,
    '&:hover': {
      borderColor: state.isFocused ? 'none' : '#40a9ff'
    }
  }),
  input: (base) => ({
    ...base,
    color: '#000',
    margin: 0,
    padding: 0
  }),
  dropdownIndicator: () => ({
    display: 'none'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#C4C4C4',
    fontSize: '14px'
  }),
  // input: (provided) => ({
  //   ...provided,
  //   color: "#0091d4",
  //   fontSize: "16px",
  // }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    zIndex: 10
  })
  // option: (provided) => ({
  //   ...provided,
  //   backgroundColor: "transparent",
  //   padding: "17px",
  //   "&:hover": {
  //     color: "#0091d4",
  //     cursor: "pointer",
  //   },
  // }),
};

const CreateCompany = (props) => {
  const { createCompany, countries, cities, response, setResponse } =
    useContext(CompaniesContext);
  const { modalVisible, setModalVisible, modal } = props;
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [value, setValue] = useState('');
  const [form] = Form.useForm();
  const companyTypes = [
    {
      name: 'buyer',
      displayName: 'Buyer'
    },
    {
      name: 'supplier',
      displayName: 'Supplier'
    }
  ];

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        createCompany(values.company, props.shouldGetCompanies).then((data) => {
          if (data && props.onFinishCreate) props.onFinishCreate(data);
        });
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const getAsyncOptions = async (inputValue, callback) => {
    if (!inputValue) return;
    return fetch(
      `${process.env.REACT_APP_AUTOCOMPLETE_API}/address?address=${inputValue}`,
      {
        headers: {
          'x-api-key': 'a5dd0715-6574-4cd7-be77-0d1df3b48ebd',
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      }
    )
      .then(async (res) => {
        const data = await res.json();
        console.log('data:', data);
        if (data.message) callback([]);
        callback([data]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getAsyncOptions(inputText, callback);
    }, 1000),
    []
  );

  const handleInputChange = (value) => {
    setValue(value);
  };

  const handleChange = (value) => {
    if (!value) return;
    setSelectedValue(value);
    form.setFieldsValue({
      company: {
        country_id: value.country,
        city_id: value.city,
        postcode: value.zipCode,
        address: value.fullAddress
      }
    });
  };

  useEffect(() => {
    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'create') {
    return (
      <Modal
        title={`Add new company`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Owner UUID"
            name={['company', 'owner_id']}
            {...ajaxErrorFieldValidationProperties(response, 'owner_id')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Name"
            name={['company', 'name']}
            {...ajaxErrorFieldValidationProperties(response, 'name')}
            rules={[
              { required: true, message: 'Please input a company name!' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Industry"
            name={['company', 'industry']}
            {...ajaxErrorFieldValidationProperties(response, 'industry')}
            rules={[{ required: true, message: 'Please input a industry!' }]}
          >
            <Input placeholder="Industry" />
          </Form.Item>
          <Form.Item
            label="Parent Company"
            name={['company', 'parent_company']}
            {...ajaxErrorFieldValidationProperties(response, 'parent_company')}
            rules={[
              { required: true, message: 'Please input a parent company!' }
            ]}
          >
            <Input placeholder="Parent Company" />
          </Form.Item>
          <Form.Item
            label="DUNS Number"
            name={['company', 'duns_number']}
            {...ajaxErrorFieldValidationProperties(response, 'duns_number')}
          >
            <Input placeholder="DUNS Number" />
          </Form.Item>
          <Form.Item
            label="Tax Jurisdiction Number"
            name={['company', 'tax_jurisdiction_code']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'tax_jurisdiction_code'
            )}
          >
            <Input placeholder="Tax Jurisdiction Number" />
          </Form.Item>
          <Form.Item
            label="VAT id"
            name={['company', 'vat_id']}
            {...ajaxErrorFieldValidationProperties(response, 'vat_id')}
          >
            <Input placeholder="VAT id" />
          </Form.Item>
          <Form.Item
            label="Ebit"
            name={['company', 'ebit']}
            {...ajaxErrorFieldValidationProperties(response, 'ebit')}
          >
            <Input placeholder="Ebit" />
          </Form.Item>
          <Form.Item
            label="Website"
            name={['company', 'website']}
            {...ajaxErrorFieldValidationProperties(response, 'website')}
          >
            <Input placeholder="Website" />
          </Form.Item>
          <Form.Item
            label="Short description"
            name={['company', 'short_description']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'short_description'
            )}
            rules={[
              { required: true, message: 'Please input a short description!' }
            ]}
          >
            <Input placeholder="Short description" />
          </Form.Item>
          <Form.Item
            label="Full address"
            name={['company', 'full_address']}
            {...ajaxErrorFieldValidationProperties(response, 'country_id')}
          >
            <AsyncSelect
              styles={customStyles}
              cacheOptions
              defaultOptions
              value={selectedValue}
              getOptionLabel={(e) => e.fullAddress}
              getOptionValue={(e) => e.fullAddress}
              loadOptions={loadOptions}
              noOptionsMessage={() => 'Nothing found'}
              onInputChange={handleInputChange}
              onChange={handleChange}
              placeholder={'Search...'}
            />
          </Form.Item>

          <Form.Item
            label="Country"
            name={['company', 'country_id']}
            {...ajaxErrorFieldValidationProperties(response, 'country_id')}
            rules={[{ required: true, message: 'Please select a country!' }]}
          >
            <Input placeholder="Country" disabled />
          </Form.Item>
          <Form.Item
            label="City"
            name={['company', 'city_id']}
            {...ajaxErrorFieldValidationProperties(response, 'city_id')}
            rules={[{ required: true, message: 'Please select a city!' }]}
          >
            <Input placeholder="City" disabled />
          </Form.Item>
          <Form.Item
            label="Postal code"
            name={['company', 'postcode']}
            {...ajaxErrorFieldValidationProperties(response, 'postcode')}
            rules={[
              { required: true, message: 'Please input a postal code!' },
              {
                min: 4,
                message: 'Postal code must be between 4 and 9 characters!'
              },
              {
                max: 9,
                message: 'Postal code must be between 4 and 9 characters!'
              }
            ]}
          >
            <Input placeholder="Postal code" />
          </Form.Item>

          <Form.Item
            label="Address"
            name={['company', 'address']}
            {...ajaxErrorFieldValidationProperties(response, 'address')}
            rules={[{ required: true, message: 'Please select address!' }]}
          >
            <Input placeholder="Address" disabled />
          </Form.Item>
          <Form.Item
            label="Turnover"
            name={['company', 'turnover']}
            {...ajaxErrorFieldValidationProperties(response, 'turnover')}
          >
            <Input placeholder="Turnover" />
          </Form.Item>
          <Form.Item
            label="Type"
            name={['company', 'type']}
            {...ajaxErrorFieldValidationProperties(response, 'city_id')}
            rules={[{ required: true, message: 'Please input type!' }]}
          >
            <Select placeholder="Type">
              {companyTypes?.length > 0 &&
                companyTypes.map((type) => {
                  return (
                    <Option key={`${type.name}-type`} value={type.name}>
                      {type.displayName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Communication language"
            name={['company', 'communication_language']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'communication_language'
            )}
            rules={[
              { required: true, message: 'Please input communication language' }
            ]}
          >
            <Input placeholder="Communication language" />
          </Form.Item>
          <Form.Item
            label="Email"
            name={['company', 'email']}
            {...ajaxErrorFieldValidationProperties(response, 'email')}
            rules={[{ required: true, message: 'Please input email' }]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Number of employees"
            name={['company', 'number_of_employees']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'number_of_employees'
            )}
            rules={[
              { required: true, message: 'Please input number of employees' }
            ]}
          >
            <Input placeholder="Number of employees" />
          </Form.Item>
          <Form.Item
            label="Phone"
            name={['company', 'phone']}
            {...ajaxErrorFieldValidationProperties(response, 'phone')}
            rules={[{ required: true, message: 'Please input phone' }]}
          >
            <Input placeholder="Phone" />
          </Form.Item>
          {/* ostavljam ti logo, company presentation, reference list, owner id, representative, supplier type */}
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default CreateCompany;
