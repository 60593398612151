import React, { useContext, useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import styled from 'styled-components';
import logo from '../../img/logo.svg';
import { Link, withRouter } from 'react-router-dom';
import {
  AppstoreOutlined,
  BankOutlined,
  FileDoneOutlined,
  FlagOutlined,
  GlobalOutlined,
  TeamOutlined,
  MessageOutlined
} from '@ant-design/icons';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import { APP_NAME } from '../../consts/globals';

import { AdminContext } from '../../contexts/AdminContext';

const StyledSider = styled(Layout.Sider)`
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  transition: all 0.2s ease-out;
`;

const StyledLogo = styled.div`
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;
  transition: all 0.4s ease-out;

  img {
    height: 40px;
  }
`;

const StyledIcon = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: ${(props) => (props.toggleCollapse ? '20px' : '0px')};
  margin-bottom: ${(props) => (props.toggleCollapse ? '12px' : '0px')};
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const Sidebar = (props) => {
  const { userRole } = useContext(AdminContext);
  const { location } = props;
  const [toggleCollapse, setToggleCollapse] = useState(false);

  const handleSidebarToggle = () => {
    setToggleCollapse(!toggleCollapse);
  };

  return (
    <StyledSider width={toggleCollapse ? '80' : '256'}>
      <StyledLogoWrapper>
        {!toggleCollapse && (
          <StyledLogo>
            <Link to="/">
              <img src={logo} alt="yvoo" />
            </Link>
          </StyledLogo>
        )}
        <StyledIcon
          onClick={handleSidebarToggle}
          toggleCollapse={toggleCollapse}
        >
          {toggleCollapse ? (
            <MenuFoldOutlined
              style={{ fontSize: '32px', color: '#1990ff', cursor: 'pointer' }}
              onClick={handleSidebarToggle}
            />
          ) : (
            <MenuUnfoldOutlined
              style={{ fontSize: '32px', color: '#1990ff', cursor: 'pointer' }}
              onClick={handleSidebarToggle}
            />
          )}
        </StyledIcon>
      </StyledLogoWrapper>
      {(() => {
        switch (userRole) {
          case `${APP_NAME}-admin`:
            return (
              <Menu
                theme="dark"
                selectedKeys={[location.pathname]}
                mode="inline"
              >
                <Menu.Item key="/users">
                  <Link to="/users">
                    <TeamOutlined />
                    <span>Users</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/countries">
                  <Link to="/countries">
                    <FlagOutlined />
                    <span>Countries</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/cities">
                  <Link to="/cities">
                    <GlobalOutlined />
                    <span>Cities</span>
                  </Link>
                </Menu.Item>
                {/*<Menu.Item key="/news">*/}
                {/*  <Link to="/news">*/}
                {/*    <FileTextOutlined />*/}
                {/*    <span>News</span>*/}
                {/*  </Link>*/}
                {/*</Menu.Item>*/}
                {/*<Menu.Item key="/invoices">*/}
                {/*  <Link to="/invoices">*/}
                {/*    <EuroOutlined />*/}
                {/*    <span>Invoices</span>*/}
                {/*  </Link>*/}
                {/*</Menu.Item>*/}
                <Menu.Item key="/legal">
                  <Link to="/legal">
                    <FileDoneOutlined />
                    <span>Legal</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/companies">
                  <Link to="/companies">
                    <BankOutlined />
                    <span>Companies</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/quickscans">
                  <Link to="/quickscans">
                    <BankOutlined />
                    <span>Quickscans</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/custom-search">
                  <Link to="/custom-search">
                    <BankOutlined />
                    <span>Custom searches</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/categories">
                  <Link to="/categories">
                    <AppstoreOutlined />
                    <span>Categories</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/messages">
                  <Link to="/messages">
                    <MessageOutlined />
                    <span>Messages</span>
                  </Link>
                </Menu.Item>
              </Menu>
            );

          case `${APP_NAME}-editor`:
            return (
              <Menu
                theme="dark"
                selectedKeys={[location.pathname]}
                mode="inline"
              >
                <Menu.Item key="/countries">
                  <Link to="/countries">
                    <FlagOutlined />
                    <span>Countries</span>
                  </Link>
                </Menu.Item>
              </Menu>
            );

          default:
            return (
              <Menu
                theme="dark"
                selectedKeys={[location.pathname]}
                mode="inline"
              >
                <Menu.Item key="/countries">
                  <Link to="/countries">
                    <FlagOutlined />
                    <span>Countries</span>
                  </Link>
                </Menu.Item>
              </Menu>
            );
        }
      })()}
    </StyledSider>
  );
};

export default withRouter(Sidebar);
