import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input } from 'antd';

import { CompaniesContext } from '../../contexts/CompaniesContext';
import { defaultFormLayout } from '../../consts/formStyles';
import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';

const EditCompanyOwnership = (props) => {
  const { editCompanyOwnership, response, setResponse } =
    useContext(CompaniesContext);

  const { modalVisible, setModalVisible, modal, selectedCompany } = props;
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        editCompanyOwnership(values.company, selectedCompany.id);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    //There is a some sort of race condition problem going on with initialValues and resetFields() in antd4
    //I have to use this use effect so I can reset form values to new values when form loads. Otherwise form will retain old data.
    if (modalVisible) {
      form.resetFields();
      form.setFieldsValue({
        company: {
          owner_id: selectedCompany.owner_id
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  useEffect(() => {
    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'company-ownership') {
    return (
      <Modal
        title={`Edit ${selectedCompany.name} company ownership`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        width="50%"
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Owner UUID"
            name={['company', 'owner_id']}
            {...ajaxErrorFieldValidationProperties(response, 'owner_id')}
            rules={[{ required: true, message: 'Please input an owner UUID!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default EditCompanyOwnership;
