import React, { useState } from 'react';
import { Button, Row, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import CreateCategoryFamily from './CreateCategoryFamily';

const { Option } = Select;

const StyledButton = styled(Button)``;

const CategoryFamiliesList = (props) => {
  const {
    justifyContent,
    families,
    selectedSegment,
    familySelected,
    getFamilies,
    enableCreate,
    width
  } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');

  return (
    <Row justify={justifyContent} style={{ gap: '8px' }}>
      <Select
        label="Family"
        showSearch
        disabled={!(selectedSegment && families.length > 0)}
        style={{ width: width ?? 200 }}
        placeholder="Select a family"
        optionFilterProp="children"
        onChange={familySelected}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {families.map((family) => {
          return (
            <Option value={family.family} key={family.family}>
              {family.family_name}
            </Option>
          );
        })}
      </Select>
      {enableCreate ? (
        <StyledButton
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          disabled={!selectedSegment}
          onClick={() => {
            setModalVisible(true);
            setModal('create-family');
          }}
        />
      ) : null}

      {selectedSegment && families.length && enableCreate > 0 ? (
        <CreateCategoryFamily
          modalVisible={modalVisible}
          modal={modal}
          segment={selectedSegment}
          category={families[0].category_id}
          getFamilies={getFamilies}
          setModalVisible={setModalVisible}
        />
      ) : null}
    </Row>
  );
};

export default CategoryFamiliesList;
