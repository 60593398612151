import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Select } from 'antd';

import { defaultFormLayout } from '../../consts/formStyles';
import { ajaxErrorFieldValidationProperties } from '../helpers/formHelpers';
import { AdminQuickscansContext } from '../../contexts/AdminQuickscansContext';

const { Option } = Select;

const CreateQuickscan = (props) => {
  const {
    createCompany,
    countries,
    cities,
    response,
    setResponse
  } = useContext(AdminQuickscansContext);
  const { modalVisible, setModalVisible, modal } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const companyTypes = [
    {
      name: 'buyer',
      displayName: 'Buyer'
    },
    {
      name: 'supplier',
      displayName: 'Supplier'
    }
  ];

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        createCompany(values.company, props.shouldGetCompanies).then((data) => {
          if (props.onFinishCreate) props.onFinishCreate(data);
        });
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'create') {
    return (
      <Modal
        title={`Add new company`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form {...defaultFormLayout} form={form}>
          <Form.Item
            label="Owner UUID"
            name={['company', 'owner_id']}
            {...ajaxErrorFieldValidationProperties(response, 'owner_id')}
            rules={[{ required: true, message: 'Please input an owner UUID!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Name"
            name={['company', 'name']}
            {...ajaxErrorFieldValidationProperties(response, 'name')}
            rules={[
              { required: true, message: 'Please input a company name!' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Industry"
            name={['company', 'industry']}
            {...ajaxErrorFieldValidationProperties(response, 'industry')}
            rules={[{ required: true, message: 'Please input a industry!' }]}
          >
            <Input placeholder="Industry" />
          </Form.Item>
          <Form.Item
            label="Parent Company"
            name={['company', 'parent_company']}
            {...ajaxErrorFieldValidationProperties(response, 'parent_company')}
            rules={[
              { required: true, message: 'Please input a parent company!' }
            ]}
          >
            <Input placeholder="Parent Company" />
          </Form.Item>
          <Form.Item
            label="DUNS Number"
            name={['company', 'duns_number']}
            {...ajaxErrorFieldValidationProperties(response, 'duns_number')}
            rules={[{ required: true, message: 'Please input a DUNS number!' }]}
          >
            <Input placeholder="DUNS Number" />
          </Form.Item>
          <Form.Item
            label="Tax Jurisdiction Number"
            name={['company', 'tax_jurisdiction_code']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'tax_jurisdiction_code'
            )}
            rules={[
              {
                required: true,
                message: 'Please input a tax Jurisdiction number!'
              }
            ]}
          >
            <Input placeholder="Tax Jurisdiction Number" />
          </Form.Item>
          <Form.Item
            label="VAT id"
            name={['company', 'vat_id']}
            {...ajaxErrorFieldValidationProperties(response, 'vat_id')}
            rules={[{ required: true, message: 'Please input a vat id!' }]}
          >
            <Input placeholder="VAT id" />
          </Form.Item>
          <Form.Item
            label="Ebit"
            name={['company', 'ebit']}
            {...ajaxErrorFieldValidationProperties(response, 'ebit')}
            rules={[{ required: true, message: 'Please input a ebit!' }]}
          >
            <Input placeholder="Ebit" />
          </Form.Item>
          <Form.Item
            label="Website"
            name={['company', 'website']}
            {...ajaxErrorFieldValidationProperties(response, 'website')}
            rules={[{ required: true, message: 'Please input a Website!' }]}
          >
            <Input placeholder="Website" />
          </Form.Item>
          <Form.Item
            label="Short description"
            name={['company', 'short_description']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'short_description'
            )}
            rules={[
              { required: true, message: 'Please input a short description!' }
            ]}
          >
            <Input placeholder="Short description" />
          </Form.Item>
          <Form.Item
            label="Country"
            name={['company', 'country_id']}
            {...ajaxErrorFieldValidationProperties(response, 'country_id')}
            rules={[{ required: true, message: 'Please select a country!' }]}
          >
            <Select placeholder="Country">
              {countries.map((country) => {
                return (
                  <Option key={country.id} value={country.id}>
                    {`${country.name} (${country.iso})`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="City"
            name={['company', 'city_id']}
            {...ajaxErrorFieldValidationProperties(response, 'city_id')}
            rules={[{ required: true, message: 'Please select a city!' }]}
          >
            <Select placeholder="City">
              {cities.map((city) => {
                return (
                  <Option key={city.id} value={city.id}>
                    {`${city.name} (${city.postal_code})`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Postal code"
            name={['company', 'postcode']}
            {...ajaxErrorFieldValidationProperties(response, 'postcode')}
            rules={[
              { required: true, message: 'Please input a postal code!' },
              {
                min: 4,
                message: 'Postal code must be between 4 and 9 characters!'
              },
              {
                max: 9,
                message: 'Postal code must be between 4 and 9 characters!'
              }
            ]}
          >
            <Input placeholder="Postal code" />
          </Form.Item>
          <Form.Item
            label="Address"
            name={['company', 'address']}
            {...ajaxErrorFieldValidationProperties(response, 'address')}
            rules={[{ required: true, message: 'Please input a address' }]}
          >
            <Input placeholder="Address" />
          </Form.Item>
          <Form.Item
            label="Turnover"
            name={['company', 'turnover']}
            {...ajaxErrorFieldValidationProperties(response, 'turnover')}
            rules={[{ required: true, message: 'Please input a turnover' }]}
          >
            <Input placeholder="Turnover" />
          </Form.Item>
          <Form.Item
            label="Type"
            name={['company', 'type']}
            {...ajaxErrorFieldValidationProperties(response, 'city_id')}
            rules={[{ required: true, message: 'Please input type!' }]}
          >
            <Select placeholder="Type">
              {companyTypes.map((type) => {
                return (
                  <Option key={`${type.name}-type`} value={type.name}>
                    {type.displayName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Communication language"
            name={['company', 'communication_language']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'communication_language'
            )}
            rules={[
              { required: true, message: 'Please input communication language' }
            ]}
          >
            <Input placeholder="Communication language" />
          </Form.Item>
          <Form.Item
            label="Email"
            name={['company', 'email']}
            {...ajaxErrorFieldValidationProperties(response, 'email')}
            rules={[{ required: true, message: 'Please input email' }]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Number of employees"
            name={['company', 'number_of_employees']}
            {...ajaxErrorFieldValidationProperties(
              response,
              'number_of_employees'
            )}
            rules={[
              { required: true, message: 'Please input number of employees' }
            ]}
          >
            <Input placeholder="Number of employees" />
          </Form.Item>
          <Form.Item
            label="Phone"
            name={['company', 'phone']}
            {...ajaxErrorFieldValidationProperties(response, 'phone')}
            rules={[{ required: true, message: 'Please input phone' }]}
          >
            <Input placeholder="Phone" />
          </Form.Item>
          {/* ostavljam ti logo, company presentation, reference list, owner id, representative, supplier type */}
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

export default CreateQuickscan;
