import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Button } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

import { CountriesContext } from '../../contexts/CountriesContext';
import CreateCountry from './CreateCountry';
import EditCountry from './EditCountry';
import DeleteCountry from './DeleteCountry';
import columnSearch from '../column-search/CustomColumnSearch';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const CountriesList = () => {
  const { totalCountries, getCountries, countries, loading } =
    useContext(CountriesContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({});

  const transformedCountries = countries.map((country) => {
    const countryName = country.name
      .split(' ')
      .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(' ');

    return {
      id: country.id,
      name: countryName,
      iso: country.iso.toUpperCase(),
      visible: !!country.visible,
      best_buy: country.best_buy
    };
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('name', getCountries, 'name')
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('id', getCountries, 'id')
    },
    {
      title: 'ISO',
      dataIndex: 'iso',
      ...columnSearch('iso', getCountries, 'iso')
    },
    {
      title: 'Action',
      render: (country) => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('edit');
              setSelectedCountry(country);
            }}
          >
            <EditOutlined />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        />
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={(country) => country.id}
        dataSource={transformedCountries}
        pagination={{ total: totalCountries }}
        scroll={{ x: 'max-content' }}
        loading={loading}
        onChange={(pagination, filters, sorter) => {
          getCountries(
            pagination.pageSize,
            pagination.current,
            filters,
            sorter
          );
        }}
      />
      <CreateCountry
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <EditCountry
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCountry={selectedCountry}
      />
      <DeleteCountry
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedCountry={selectedCountry}
      />
    </Fragment>
  );
};

export default CountriesList;
