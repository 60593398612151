import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Divider } from 'antd';
import EditMessage from './EditMessage';
import DeleteMessage from './DeleteMessage';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import columnSearch from '../column-search/CustomColumnSearch';
import { MessagesContext } from '../../contexts/MessagesContext';
import moment from 'moment';

const StyledIcon = styled.span`
  cursor: pointer;
`;

const MessagesList = () => {
  const { messages, loading } = useContext(MessagesContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [selectedMessage, setSelectedMessage] = useState({});

  const columns = [
    {
      title: 'Product',
      dataIndex: 'product',
      sorter: (a, b) => a.product.localeCompare(b.product),
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('product')
    },
    {
      title: 'User Email',
      dataIndex: 'userEmail',
      sorter: (a, b) => a.userEmail - b.userEmail,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Location',
      dataIndex: 'location'
    },
    {
      title: 'Date',
      dataIndex: 'dateCreated',
      sorter: (a, b) => a.dateCreated.localeCompare(b.dateCreated),
      sortDirections: ['descend', 'ascend'],
      ...columnSearch('dateCreated'),
      render: (dateCreated) => {
        return <span>{moment(dateCreated).format('YYYY-MM-DD HH:mm:ss')}</span>;
      }
    },
    {
      title: 'Suppliers',
      dataIndex: 'suppliers',
      render: (suppliers) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 'fit-content'
            }}
          >
            {suppliers.map((supplier) => (
              <div>{supplier}</div>
            ))}
          </div>
        );
      }
    },
    {
      title: 'Product specification',
      dataIndex: 'productSpecification',
      render: (productSpecification) => {
        return (
          <div style={{ width: '100%', maxWidth: '400px' }}>
            <span>{productSpecification}</span>
          </div>
        );
      }
    },

    // {
    //   title: 'Scan',
    //   dataIndex: 'quickscan',
    //   render: (quickscan) => {
    //     return <span>{quickscan ? 'yes' : 'no'}</span>;
    //   }
    // },
    // {
    //   title: 'Assesment',
    //   dataIndex: 'selfAssesment',
    //   render: (selfAssesment) => {
    //     return <span>{selfAssesment ? 'yes' : 'no'}</span>;
    //   }
    // },
    // {
    //   title: 'Audit',
    //   dataIndex: 'audit',
    //   render: (audit) => {
    //     return <span>{audit ? 'yes' : 'no'}</span>;
    //   }
    // },
    {
      title: 'Action',
      render: (message) => (
        <span>
          <StyledIcon
            onClick={() => {
              setSelectedMessage(message);
              setModal('edit');
              setModalVisible(true);
            }}
          >
            <EditOutlined />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setSelectedMessage(message);
              setModal('delete');
              setModalVisible(true);
            }}
          >
            <DeleteOutlined />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <Table
        columns={columns}
        rowKey={(message) => message.id}
        dataSource={messages}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
        loading={loading}
      />
      <EditMessage
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedMessage={selectedMessage}
      />
      <DeleteMessage
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedMessage={selectedMessage}
      />
    </Fragment>
  );
};

export default MessagesList;
